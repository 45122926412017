import React, { useState, useContext, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTheme } from '@mui/material';
import { UserContext } from '../../../contexts/UserContext';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AllOutIcon from '@mui/icons-material/AllOut';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinkIcon from "@mui/icons-material/Link";
import {  IconButton } from "@mui/material";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import RetainIcon from '../../../icons/RetainIcon.png';
import ConsiderIcon from '../../../icons/ConsiderRoadmap.png';
import AcceptIcon from '../../../icons/AcceptIcon.png';
import ClarificationIcon from '../../../icons/NeedClarificationIcon.png';
import { Fade } from '@mui/material';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { components } from 'react-select';

import download_icon from '../../../icons/download_icon.png';
import schedule_icon from '../../../icons/schedule_icon.png';

import {
  Box, 
  Button,
  ButtonGroup,
  Skeleton,
  Collapse,
  TextField,
  Paper,
  Chip,
  Menu,
  MenuItem
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';

import CancelIcon from '@mui/icons-material/Cancel';

import { group_by_order, find_row_span } from '../../../util/GroupingFunctions.js';
import { queryDB } from "../../../util/QueryDB";
import { EmployeeCards } from '../../../util/Loading.js';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs library

import InternalComponent from './InternalComponent';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { round_table_get_query_1,round_table_get_query_2,round_table_get_query_3,round_table_get_query_4,
         round_table_get_query_5,round_table_get_query_6
       } from '../../../catalystDB/queryGenerator.js';

export default function RoundTable()
{
    const [searchText, setSearchText] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [viewSelected, setViewSelected] = useState("Internal");
    const [anchorEl, setAnchorEl] = useState(null);
    const [toggleOpenOrClosed,setToggleOpenOrClosed] = useState(true);

    var ComponentToShow = <></>;

    if (viewSelected === "External" ) {
        ComponentToShow =  <NewTableComponent searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed}/> ;
    }
    else if (viewSelected === "Internal") {
        ComponentToShow = <InternalComponent selectedOption={selectedOption} setSelectedOption={setSelectedOption} searchText={searchText} setSearchText={setSearchText}/>;
    }

    return(
        <>
        <Box sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            left: 300,
            right: 300,
            top: 20,
            marginLeft: "auto",
            marginRight: "auto",
            color: "white",
            fontWeight: "bold",
            cursor: "default",
            transition: "top .5s",
            paddingLeft: "250px"
        }}
        >
            <ButtonGroup>
                    <Button
                        variant={viewSelected === "Internal" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => {
                            setViewSelected("Internal");
                        }}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        Internal
                    </Button>

                    <Button
                        variant={viewSelected === "External" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => setViewSelected("External")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        External
                    </Button>
            </ButtonGroup>
            </Box>
            <div className="page-content-2">
                {
                    ComponentToShow
                    // toggleSwitch ? <TimelineComponent viewSelected={viewSelected} setViewSelected={setViewSelected} /> : <OtherComponent />
                }
            </div>
        </>
    );
}

function NewTopBar({searchText,setSearchText,selectedOption,setSelectedOption,toggleOpenOrClosed,setToggleOpenOrClosed}){
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false); 
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedLabel, setSelectedLabel] = React.useState('Ongoing'); // Initial label for the button
    const minimize = false;

    const handleOpenPopup = (something) => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleCloseDialog = () => {
		setOpenDialog(false);
	  };

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    function handleSearchButtonClick() {
        setSearchText("");
        setOpen(!open);
    }

    function handleMeetingButtonClick(){
        console.log("meeting clicked");
    }

    const handleSelectOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const handleOpenClosedOrOngoingTab = (value) => {
        setSelectedLabel(value);
       if (value == 'Completed'){
			setToggleOpenOrClosed(false);
	   }
	   else if(value == 'Pending'){
            setToggleOpenOrClosed(null);
	   }
	   else if (value == 'Ongoing'){
            setToggleOpenOrClosed(true);
	   }
       handleSelectClose();
    }

    return (
        <>
            <div className="external-top-bar">
                <div className="external-top-bar-left" style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                }}>
                    <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    width: "calc(50vw)",
                    overflowX: "scroll"
                }}
                    className="filter-sort-overflow-container"
                >
                     {/* <ServerList
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        height={"50px"}
                        required={true}
                    /> */}
                    {
                        searchText !== "" ?
                            <Chip
                                key={"search-chip"}
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{

                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                            // width: "60px",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                            // textAlign: "center"
                                        }}>
                                            {/* <Tooltip title={searchText} > */}
                                            {searchText}
                                            {/* </Tooltip> */}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    <div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
                    </Box>
                </div>
               
                <div className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                            <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus />
                        </Collapse>
                        <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
                        <Tooltip title="Options">
                            <Button
                            id="customized-button"
                            aria-controls={Boolean(anchorEl) ? 'customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleSelectOpen}
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{fontSize:"11px",width:"110px"}}
                            >
                            {selectedLabel} {/* Display the selected label */}
                            </Button>
                        </Tooltip>
                        <Menu
                            id="customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleSelectClose}
                        >
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Ongoing')}
                                disableRipple
                            >
                                <AssignmentTurnedInIcon fontSize="small" sx={{ fontSize: "15px" }} />
                                &nbsp;&nbsp;Ongoing
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Pending')}
                                disableRipple
                            >
                                <AllOutIcon fontSize="small" sx={{ fontSize: "17px" }} />
                                &nbsp;&nbsp;Pending
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Completed')}
                                disableRipple
                            >
                                <BlockIcon fontSize="small" sx={{ fontSize: "15px" }} />
                                &nbsp;&nbsp;Completed
                            </MenuItem>
                        </Menu>
                        <Tooltip title="Meeting"><Button variant="contained" size="small" onClick={handleOpenPopup} sx={{padding: 0}}>
                            {/* <PendingActionsIcon /> */}
                            <img src={schedule_icon} height={30} width={30}/>
                        </Button></Tooltip>
                    </ButtonGroup>
                    <Dialog open={openPopup} onClose={handleClosePopup} sx={{
                        display: minimize ? "none" : "block"
                    }} fullScreen>
                        <DialogActions>
                            <Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                        </DialogActions>
                        <DialogContent>
                            {!iframeLoaded && (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                        borderRadius: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                        animation="wave"
                                    />
                                </div>
                            )}
                            <iframe
                                src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/MeetingSchedule?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                                width="100%"
                                height="100%"
                                title="External Content"
                                onLoad={handleIframeLoad}
                                style={{ display: iframeLoaded ? 'block' : 'none' }}
                            />
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

function NewTableComponent({ selectedOption, setSelectedOption,searchText,setSearchText, toggleOpenOrClosed,setToggleOpenOrClosed }) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState([]);
    const [updatedValue,setUpdatedValue] = useState(false);
    //const [searchText, setSearchText] = useState("");
    const {loginUserDept,GlobalServer,loginUserEmail} = useContext(UserContext);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "No Server" : GlobalServer;
    const {teamDetails, userDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const userServers = teamDetails.current[user_email].Servers;
    const userModule = teamDetails.current[loginUserEmail.current].Module;
    const theme = useTheme();

    var rrows = [];

    async function QueryData() {
        try {
            if (toggleOpenOrClosed === true) {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(round_table_get_query_1(loginUserDept,userServers,userModule));
                    return result;
                }
                else{
                    const result = await queryDB(round_table_get_query_2(loginUserDept,SelectedGlobalServer));
                    return result;
                }
            }
            else if (toggleOpenOrClosed === null) {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(round_table_get_query_5(loginUserDept,userServers,userModule));
                    return result;
                }
                else{
                    const result = await queryDB(round_table_get_query_6(loginUserDept,SelectedGlobalServer));
                    return result;
                }
            }
            else{
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(round_table_get_query_3(loginUserDept,userServers,userModule));
                    return result;
                }
                else{
                    const result = await queryDB(round_table_get_query_4(loginUserDept,SelectedGlobalServer));
                    return result;
                }
            }
        } catch (error) {
            //console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        setLoaded(undefined);
   }, [GlobalServer,toggleOpenOrClosed]);

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                //console.log('Query result - Low Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.ExternalFeatureReport.Feature);
                        rrow.push(item.ExternalFeatureReport.RequestedBy);
                        rrow.push(item.ExternalFeatureReport.Module);
                        rrow.push(item.ExternalFeatureReport.CreatorRowid);
                        rrow.push(item.ExternalFeatureReport.ROWID);
                        rrow.push(item.ExternalFeatureReport.RevisitTime);
                        rrow.push(item.ExternalFeatureReport.MeetingScheduled);
                        rrow.push(item.ExternalFeatureReport.Over_All_ETA);
                        rrow.push(item.ExternalFeatureReport.ScheduledMeetingTime);
                        rrow.push(item.ExternalFeatureReport.MeetingScheduled);
                        rrow.push(item.ExternalFeatureReport.FilestoreID); //to fetch filestore id for document
                        rrow.push(item.ExternalFeatureReport.ReleasePlan); 
                        rrow.push(item.ExternalFeatureReport.CREATEDTIME);
                        rrow.push(item.ExternalFeatureReport.TeamName);
                        rrow.push(item.ExternalFeatureReport.DocumentAgreed);//No:14
                        rrow.push(item.ExternalFeatureReport.FeaturePriority);
                        rrow.push(item.ExternalFeatureReport.Module_Servers);//NO:16
                        rrow.push(item.ExternalFeatureReport.CompletionDate);
                        rrow.push(item.ExternalFeatureReport.TasklistStatus);
                        rrow.push([undefined, "", " ", null].includes(item.ExternalFeatureReport.ReleasePlannerUrl) ? "-" : item.ExternalFeatureReport.ReleasePlannerUrl);
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying Roadmap data:', error);
                setLoaded(null);

            });
    }, [selectedOption,GlobalServer,toggleOpenOrClosed]);

    //console.log(rows);

    if (loaded === undefined) {
        return (
            <>
            <NewTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed}/>
            <br></br>
            <Skeleton variant="rectangular" width={"100%"} height={115} />
            <br></br>
            <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1}} />
            </>
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <>
            <NewTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed}/>
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.background.text
              }}>
                No RoadMap Found :(
              </h2>
              </>
        );
    } else {

        return (
            <>
                <NewTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed}/>
                <NewTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue}  toggleOpenOrClosed={toggleOpenOrClosed}/>
            </>
        );
    }
}

function NewTable({ selectedOption, setSelectedOption,rows ,searchText,setSearchText,updatedValue,setUpdatedValue,toggleOpenOrClosed}){

    rows = rows.filter(row_ => {
		for (const i in row_) {
            const item = row_[i];
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);
        //console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [2]);
        // console.log(rowSpanInfo);
    }
    
    return (
        <>
            <div className="external-table">
                <TableContainer component={Paper} className="external-table-container">
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow className="external-table-head"

// onMouseEnter={() => setMoreVisible(true)}
// onMouseLeave={() => setMoreVisible(false)}
                            
                            >
                                <TableCell align="left" className="external-table-grouper-header" />
                                <TableCell align="left" style={{ width: '1vw' }}>S.No.</TableCell>
                                <TableCell align="center" style={{ width: '20vw' }}>Feature</TableCell>
                                <TableCell align="center" style={{ width: '10vw' }}>Requested Service</TableCell> 
                                <TableCell align="center" style={{ width: '10vw' }}>Priority</TableCell> 
                                <TableCell align="center" style={{ width: '1vw' }}></TableCell>   
                                { 
                                    toggleOpenOrClosed === true ? 
                                        <TableCell align="center" style={{ width: '1vw' }}>Due on</TableCell>
                                    :
                                    toggleOpenOrClosed === false ?
                                        <TableCell align="center" style={{ width: '1vw' }}>Completion Date</TableCell>
                                    :
                                    toggleOpenOrClosed === null ?
                                        <TableCell align="center" style={{ width: '1vw' }}>Set ETA Date</TableCell>
                                    :
                                        <TableCell align="center" style={{ width: '1vw' }}>Set ETA Date</TableCell>
                                }
                                {/* {toggleOpenOrClosed == true ? ( 
                                    <TableCell align="center" style={{ width: '1vw' }}>Due on</TableCell> 
                                ):(
                                    <TableCell align="center" style={{ width: '1vw' }}>Completion Date</TableCell> 
                                )}                             */}
                                <TableCell align="right" className="external-table-grouper-header" />
                            </TableRow>
                        </TableHead>
                        <TableBody id="list-view">
                            {
                                Array.isArray(filteredData) ?
                                filteredData.map((row, index) => {
                                        return <NewRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} toggleOpenOrClosed={toggleOpenOrClosed}/>;
                                    })
                                    :
                                    null
                            }

                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

function NewRow({row, index , rowSpanInfo, updatedValue,setUpdatedValue,toggleOpenOrClosed}){

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const now = new Date();
    const [value, setValue] = useState( );
    const [showButtons, setShowButtons] = useState(false);
    const [disagreeDialogOpen, setDisagreeDialogOpen] = useState(false);
    const [disagreeDescription, setDisagreeDescription] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [moreVisibile, setMoreVisible] = useState(false);
        //console.log(user_email);

        // async function ModuleData() {
        //     try {
        //         const result = await queryDB(`SELECT value FROM Util Where conf = 'Module_ZVP'`);
        //         return result;
        //     } catch (error) {
        //         //console.log(error);
        //         throw error;
        //     }
        // }
    
        // var rrows=[];
        // useEffect(() => {
        //     ModuleData()
        //         .then((queryResult) => {
        //             //console.log('Query result - Low Priority:', queryResult);
        //             if (queryResult.length > 0) {
        //                 for (var item of queryResult) {
        //                     var rrow = [];
                            
        //                     rrow.push(item.Util.value);
        //                     rrows.push(rrow);
    
    
        //                 }
        //                 setValue(rrows);
        //                 // setLoaded(true);
        //             } else {
        //                 // setLoaded(false);
    
        //             }
        //         })
        //         .catch((error) => {
        //             console.error('Error querying Roadmap data:', error);
        //             // setLoaded(null);
    
        //         });
        // }, []);

    //console.log(value);
    //console.log(row[6]);
    
    var disable = false;
    if (new Date(row[5]) >= now)
    {
        disable = true;
    }
    const [revisitDisabled, setRevisitDisabled] = useState(disable);
    const [desiredTime] = useState(new Date(row[8]));


    // useEffect(() => {
        
    //     const currentTime = new Date();
    //     const timeDifference = desiredTime - currentTime;
        
    //     // Set revisitDisabled to false if the time difference is less than 10 minutes (600000 milliseconds)
    //     if (timeDifference <= 600000) {
    //         setRevisitDisabled(false);
    //         var detailsSet = [
    //             { "MeetingScheduled":true, "ROWID": row[4]},
    //          ];
             
    //         var datastore = window.catalyst.table;
    //         var table = datastore.tableId('5781000012249849');
    //         var updatePromise = table.updateRow(detailsSet);
    //         updatePromise
    //        .then((response) => {
    //                 //console.log(response.content);
    //          })
    //         .catch((err) => {
    //                 //console.log(err);
    //         });
    //     }
    //   }, [desiredTime]);


    const handleConfirmationOpen = () => {
        setConfirmationOpen(true);
      };
      
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
      };
      
    const handleAgreeButton = () => {
        setShowButtons(true);
        //setRevisitDisabled(true);
        var detailsSet = [
            { "DocumentAgreed": true, "MeetingScheduled": false, "ROWID": row[4] },
        ];

        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        //     var updatePromise = table.updateRow(detailsSet);
        //     updatePromise
        //    .then((response) => {
        //             //console.log(response.content);
        //      })
        //     .catch((err) => {
        //             //console.log(err);
        //     });
        setTimeout(() => {
            var updatePromise = table.updateRow(detailsSet);
            updatePromise
                .then((response) => {
                    // Handle the response after the update
                    // console.log(response.content);
                })
                .catch((err) => {
                    // Handle errors
                    // console.log(err);
                });
        }, 10 * 60 * 1000);
    };
    const handleDisagreeButton = () => {
        setDisagreeDialogOpen(true);
    };

    const handleDisagreeDialogClose = () => {
        setDisagreeDialogOpen(false);
    };

    const handleDisagreeSubmit = () => {
        console.log('Disagree Description:', disagreeDescription);
        setDisagreeDescription('');
        setDisagreeDialogOpen(false);
        const url = '/server/sample?table=ExternalQueryDescription&rowid=' + row[3] + '&Description=' +disagreeDescription;
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(url, {
            method: 'POST',
            headers: headers,

        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Request failed with status: ' + response.status);
                }
            })
            .then(data => {
                const statusCode = data.StatusCode;
                if (statusCode !== 3000) {
                   // handleSnackbarOpen('Error fetching data', 'error');
                }
                else {
                    //handleSnackbarOpen('Overall ETA Provided Successfully!', 'success');
                    //setOpen(!open);
                }
            })
            .catch(error => {
                console.error(error);

            });

            var detailsSet = [
                {"NeedClarification":true, "ROWID": row[4]},
             ];
             
            var datastore = window.catalyst.table;
            var table = datastore.tableId('5781000012249849');
            var updatePromise = table.updateRow(detailsSet);
            updatePromise
           .then((response) => {
                    //console.log(response.content);
             })
            .catch((err) => {
                    //console.log(err);
            });
    };

    const handleConsiderButton = () => {
        if (!revisitDisabled) {
        setOpen(!open);
        }
    };

    const handleRevisitButton = () => {
        if (!revisitDisabled) {
            setOpenConfirmation(true);
          }
    }

    const handleViewDocument = () => {
        var filestore = window.catalyst.file;
        var folder = filestore.folderId("5781000012600529");
        var file = folder.fileId(row[10]);
        var downloadPromise = file.getDownloadLink();
        downloadPromise
            .then((response) => {
                //console.log(response.content);
                //console.log("https://zvpone-709834020.catalystserverless.com" + response.content.download_url);
                // Create a hidden anchor element
                const a = document.createElement('a');
                a.style.display = 'none';

                // Set the anchor's href to the download URL
                a.href = "https://zvpone-709834020.catalystserverless.com" + response.content.download_url;

                // Set the anchor's download attribute if you want to specify a download filename
                // a.download = "filename.txt";

                // Trigger a click on the anchor element
                document.body.appendChild(a);
                a.click();

                // Clean up by removing the anchor element
                document.body.removeChild(a);
            });
    }

    const confirmRevisit = () => {
        
        setRevisitDisabled(true); 
        setOpenConfirmation(false); 
        const now = new Date();
        const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    
        var detailsSet = [
            { "RevisitTime": startOfNextMonth.toString(),"MeetingScheduled":false,ScheduledMeetingTime:"", "ROWID": row[4]},
         ];
         
        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        var updatePromise = table.updateRow(detailsSet);
        updatePromise
       .then((response) => {
                //console.log(response.content);
         })
        .catch((err) => {
                //console.log(err);
        });
      };
    
      const cancelRevisit = () => {
        setOpenConfirmation(false); 
      };

      var originalDate;
      var dueDate;
      if (toggleOpenOrClosed) {
         originalDate = row[7];
      }
      else{
         originalDate = row[17];
         dueDate = row[18];
      }
    // const originalDate = row[7];
    //const dateParts = new Date(originalDate).toDateString();
    const dateObj = new Date(originalDate);
    const DueDateDateObj = new Date(dueDate);

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' }); // Get the short month name
    const year = dateObj.getFullYear();

    const DueDateDay = DueDateDateObj.getDate();
    const DueDateMonth = DueDateDateObj.toLocaleString('en-US', { month: 'short' }); // Get the short month name
    const DueDateYear = DueDateDateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedDueDate = `${DueDateDay}-${DueDateMonth}-${DueDateYear}`;
    //console.log(formattedDate); 

    const handleLinkClick = (url) => {
        if (url && url !== "-") {
          window.open(url, "_blank", "noopener,noreferrer");
        }
      };


    return (
        <>
            <TableRow className="external-table-head"
                onMouseEnter={() => setMoreVisible(true)}
                onMouseLeave={() => setMoreVisible(false)}
            >
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`} align="center" width={"6px"} rowSpan={2 * rowSpanInfo[index][2]} sx={{
                            fontSize: "0.8rem !important",
                            backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                        }} >
                            {row[2]}
                        </TableCell>
                        :
                        null
                }
                <TableCell align="center">{index + 1}</TableCell>
                {/* <TableCell align="center" >{row[0]}</TableCell> */}
                <TableCell align="center" style={{ marginRight: row[19] !== "-" ? "8px" : "0" }}>
                    {row[0]}
                    {row[19] !== "-" && (
                    <Tooltip title="Open Link">
                    <IconButton
                        onClick={() => handleLinkClick(row[19])}
                        size="small"
                        style={{
                            color: "#007bff",
                            marginLeft: "8px", // Add spacing between text and icon
                            transition: "transform 0.2s ease, color 0.2s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <LinkIcon />
                    </IconButton>
                    </Tooltip>
                )}
                </TableCell>
                <TableCell align="center" >{row[13]}</TableCell>
                <TableCell align="center" >{row[15]}</TableCell>
                <TableCell align="center">
                    <Button variant="contained" color="action" size="small" onClick={handleViewDocument} sx={{ padding: 0 }}>
                        <Tooltip title="Click to Download Document">
                            {/* <PreviewIcon /> */}
                            <img src={download_icon} height={30} width={30} />
                        </Tooltip>
                    </Button>
                </TableCell>
                <TableCell align="center" style={{ paddingRight: 0, color: "orange", width: "5vw" }}>
                    {row[6] ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: "row !important", gap: "10px", padding: "0px" }}>
                                {showButtons || row[14] ? (
                                    <>
                                        <Button variant="contained" color="action" size="small" marginRight="8px" onClick={handleConsiderButton} disabled={revisitDisabled}>
                                            <Tooltip title="Consider"><img src={ConsiderIcon} width={"28px"} /></Tooltip>
                                        </Button>
                                        <Button variant="contained" color="action" size="small" onClick={handleRevisitButton} disabled={revisitDisabled}>
                                            <Tooltip title="Retain Roadmap"><img src={RetainIcon} width={"28px"} /></Tooltip>
                                        </Button>
                                        <MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} creatorRowID={"Rowid"}     />

                                    </>
                                ) : (
                                    <>
                                        <Button variant="contained" color="action" size="small" onClick={handleAgreeButton}>
                                            <Tooltip title="Agree">
                                                <img src={AcceptIcon} width={"28px"} />
                                            </Tooltip>
                                        </Button>
                                        <Button variant="contained" color="action" size="small" onClick={handleDisagreeButton}>
                                            <Tooltip title="Need clarification">
                                                <img src={ClarificationIcon} width={"28px"} />
                                            </Tooltip>
                                        </Button>
                                    </>
                                )}
                            </Box>

                            {/* <Dialog
                                open={disagreeDialogOpen} 
                                onClose={handleDisagreeDialogClose}
                            >
                                <DialogTitle>Need clarification</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Please provide your query for clarification.
                                    </DialogContentText>
                                    <Box
                                        noValidate
                                        component="form"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            m: 'auto',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <TextField
                                            label="Description"
                                            variant="outlined"
                                            value={disagreeDescription}
                                            onChange={(e) => setDisagreeDescription(e.target.value)}
                                            fullWidth
                                            multiline
                                        //rows={4}
                                        />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDisagreeDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDisagreeSubmit} color="primary">
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Dialog> */}

                            <Dialog
                                open={disagreeDialogOpen}
                                onClose={handleDisagreeDialogClose}
                                maxWidth="sm"
                                fullWidth>
                                <div className="external-table">
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow className="external-table-head">
                                                <TableCell align="left" style={{ width: '1vw' }}>Need clarification</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    <DialogContent>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Description:</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            label="Description"
                                                            type="text"
                                                            placeholder="Provide your query for clarification."
                                                            value={disagreeDescription}
                                                            onChange={(e) => setDisagreeDescription(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </DialogContent>
                                </div>
                                <DialogActions>
                                    <Button onClick={handleDisagreeDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDisagreeSubmit} color="primary">
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={openConfirmation} onClose={cancelRevisit}>
                                <DialogTitle>Confirm Revisit</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure, Do you want to revisit this for the next cycle?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={cancelRevisit} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={confirmRevisit} color="primary">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    ) : (
                        <>
                            {row[7] != null ?
                                row[12] == "Closed" ?
                                <>
                                    <Box sx={{
                                        fontSize: "0.8rem",
                                        color: theme.palette.background.text
                                    }}>
                                        {formattedDate}
                                    </Box>
                                    <Box  sx={{
                                        fontSize: "0.6rem",
                                        color: theme.palette.background.text
                                    }}>
                                        DueDate : {formattedDueDate}
                                    </Box>
                                </>
                                :
                                    formattedDate
                                :
                                null
                            }
                        </>
                    )}
                </TableCell>
                {
                    row[7] && (
                        <MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} row={row} />
                    )
                }
            </TableRow>
            <TableRow className="info-div-row"
                sx={{
                    backgroundColor: theme.palette.background.infoDivOuter + " !important"
                }}>
                <TableCell colSpan={10} sx={{
                    padding: "0px",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                }} >
                    {/* Content to display when the row is expanded */}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <NewHiddenRow row={row} value={value} setValue={setValue} open={open} setOpen={setOpen} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

function NewHiddenRow({ row,value,setValue,open,setOpen,updatedValue,setUpdatedValue}) {
    {
        const valuesString = row[16];
        let valuesArray;

        if (valuesString !== null && valuesString !== undefined) {
            valuesArray = valuesString.split(',');
        } else {
            // Handle the case where valuesString is null or undefined
            valuesArray = [];
        }
        const theme = useTheme();
        const [selectedDate, handleDateChange] = React.useState(null);
        const { userDetails, teamDetails, allServers } = useContext(UserContext);
        const user_email = userDetails.current.auth.email_id;
        const [effortValues, setEffortValues] = useState({});
        const [selectedBoxOptions, setSelectedBoxOptions] = useState(valuesArray);

        // const loginUser = userDetails.current.auth.email_id;
        // const loginUserDetails = teamDetails.current[loginUser];
        const [servers, setServers] = useState([]);
        
        let modules;
        
        //console.log(value);
        //const value123 = value[0].join(" ");
        //console.log(value123);
       
        if (row[2] === "ZVP") {
             modules = ['AMSServer', 'WSServer', 'RTCP', 'Media'];
            //modules = value123;
        } else {
            modules = [row[2]];
        }
        const [selectedModule, setSelectedModule] = useState(modules[0]);

        const handleSnackbarOpen = (message, severity) => {
            setNotification({
                open: true,
                message,
                severity,
            });
        };
    
        const handleSnackbarClose = () => {
            setNotification({
                ...notification,
                open: false,
            });
        };

        const handleInputChange = (module, newValue) => {
            if (newValue >= 0 && newValue <= 180) {
                setEffortValues({
                    ...effortValues,
                    [module]: newValue,
                });
            }
        };

        const [notification, setNotification] = useState({
            open: false,
            message: '',
            severity: 'success', // or 'error' for example
          });
    
        const handleSubmit = (event) => {
            setEffortValues(0);
            handleSnackbarOpen('Processing...', 'info');
            event.preventDefault();
            if (!selectedDate) {
                window.alert('Provide a date.');
                return;
            }
            const formattedDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
            const effort = JSON.stringify(effortValues);
            //const modifiedEffort = effort.replace(/"/g, "'");
            const modifiedEffort = encodeURIComponent(effort);

            //console.log(modifiedEffort);
           
            const url = '/server/sample?table=ExternalRequestFeaureETA&rowid=' + row[3] + '&OverallETA=' + formattedDate + '&User=' + user_email + '&Module=' + selectedModule + '&SelectedServers=' + selectedBoxOptions;
            const headers = {
                'Content-Type': 'application/json',
            };
            fetch(url, {
                method: 'POST',
                headers: headers,

            })
                .then(response => {

                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Request failed with status: ' + response.status);
                    }
                })
                .then(data => {
                    const statusCode = data.StatusCode;
                    if (statusCode !== 3000) {
                        handleSnackbarOpen('Error fetching data', 'error');
                    }
                    else {
                        handleSnackbarOpen('Overall ETA Provided Successfully!', 'success');
                        setOpen(!open);
                    }

                })
                .catch(error => {
                    console.error(error);

                });
        };

        // format to change for row[12]
        const originalDate = row[12];
        var months = [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
            'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ];

        const date = new Date(originalDate);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;
       
       // for row[11]
        const rowDate = row[11];
        const dateParts = originalDate.split('-');
        const rowday = dateParts[0];
        const monthNumber = dateParts[1];
        const rowyear = dateParts[2];

        // Check if the month number is valid (between 1 and 12)
        const isValidMonthNumber = !isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12;

        // Create the formatted date
        const formattedrowDate = isValidMonthNumber
            ? `${day}-${months[monthNumber - 1]}-${`${year}`}` 
            : "Invalid Date"; 

        //console.log(formattedrowDate); 
        
        //console.log(valuesArray);

        function handleSetServers(value) {
            setServers(value);
        }

        //console.log(selectedBoxOptions);

        return (
            <Box style={{
                padding: "10px 0px 10px 0px",
                backgroundColor: theme.palette.background.infoDivOuter,
            }}
            >
                <Paper className="info-div-paper"
                    sx={{
                        backgroundColor: theme.palette.background.infoDivInner + " !important"
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <Table>
                            <TableBody>
                                {/* <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Time</TableCell>
                                    <TableCell style={{paddingRight:'30px'}}>{formattedDate}</TableCell>
                                </TableRow> */}
                                <TableRow >
                                    <TableCell style={{ width: '10vw', fontWeight: 'bold' }}>Requested By:</TableCell>
                                    <TableCell  style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start !important",
                                        alignItems: "center",
                                        // backgroundColor:"blue"
                                    }}>
                                        <Box sx={{
                                            width: "30%",
                                            whiteSpace:"nowrap",
                                            justifyContent:"left",
                                            flexDirection: "row",
                                            display: "flex",
                                        }} className="round-table-employee-card-item">
                                            <EmployeeCards emails={row[1]} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>
                                        Release Planned:
                                    </TableCell>
                                    <TableCell style={{padding:"10px 22px"}}>
                                        <div style={{display:'flex',flexDirection: "row",justifyContent:'flex-start'}}>
                                            {formattedrowDate}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                
                                <TableRow>
                                    <TableCell style={{ width: '10vw', fontWeight: 'bold' }}>Overall ETA:</TableCell>
                                    <TableCell >
                                        <Box display="flex" flexDirection="column" alignItems="left" sx={{
                                            width: "250px"
                                        }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    // label={selectedDate ? 'Select a Date' : 'Provide an Overall ETA'}
                                                    value={selectedDate}
                                                    onChange={(newDate) => handleDateChange(newDate)}
                                                    renderInput={(params) => <TextField {...params} required />}
                                                    disablePast
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>
                                        Servers:
                                    </TableCell>
                                    <TableCell >
                                        <MultiSelect
                                            options={allServers}
                                            selectedOptions={servers}
                                            label={allServers}
                                            setSelectedOptions={handleSetServers}
                                            height={"40px"}
                                            selectedBoxOptions={selectedBoxOptions}
                                            setSelectedBoxOptions={setSelectedBoxOptions}
                                        />
                                    </TableCell>
                                    
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Box>
                        <Snackbar
                            open={notification.open}
                            autoHideDuration={5000}
                            onClose={handleSnackbarClose}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={handleSnackbarClose}
                                severity={notification.severity}
                            >
                                {notification.message}
                            </MuiAlert>
                        </Snackbar>
                        {/* </form> */}
                    </form>
                </Paper>
            </Box>
        );
    }
}

function MoreSection({ moreVisibile, setMoreVisible,row}) {
    const [moreAnchor, setMoreAnchor] = useState(null);

    
    function handleEditClick() {
        setMoreAnchor(null);

        var detailsSet = [
            {"NeedClarification":true, "ROWID": row[4]},
         ];
         
        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        var updatePromise = table.updateRow(detailsSet);
        updatePromise
       .then((response) => {
                //console.log(response.content);
         })
        .catch((err) => {
                //console.log(err);
        });
    }

    return (
        <TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
            <Fade in={moreVisibile} timeout={100}>
                <MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
            </Fade>
            <Menu anchorEl={moreAnchor}
                open={moreAnchor !== null}
                onClose={() => { setMoreAnchor(null) }}
            >
                <MenuItem onClick={handleEditClick}>
                    <Box style={{ display: "flex" }}>
                        
                        &nbsp;Need Clarification
                    </Box>
                </MenuItem>
            </Menu>

        </TableCell>
    );
}

function MultiSelect({ options, selectedOptions, label, setSelectedOptions, height, required = false,selectedBoxOptions,setSelectedBoxOptions }) {
    const [clearButton, setClearButton] = useState(false);
    const [isDropdownVisible, setDropdownVisibility] = useState(true);

    const handleDragStart = () => {
        setDropdownVisibility(false);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
      
        const reorderedOptions = Array.from(selectedBoxOptions);
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);
      
        setSelectedBoxOptions(reorderedOptions);
      };
      

    

    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        if (!selectedOptions.includes(selectedValue)) {
            setSelectedBoxOptions([...selectedBoxOptions, selectedValue]);
        }
    };

	function handleMouseEnter() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(true);
	}

	function handleMouseLeave() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(false);
	}

	function handleFocus() 
	{
		if (selectedOptions.length === 0) {
			return;
		}
		setClearButton(true);
	}

	function handleUnfocus(event) 
	{
		setTimeout(() => setClearButton(false), 200);
	}

	function handleClearClick() 
	{
		setSelectedOptions([]);
		setClearButton(false);
	}

    // const handleDragEnd = (result) => {
    //     if (!result.destination) {
    //       return;
    //     }
      
    //     const reorderedOptions = Array.from(selectedOptions);
    //     const [removed] = reorderedOptions.splice(result.source.index, 1);
    //     reorderedOptions.splice(result.destination.index, 0, removed);
      
    //     setSelectedOptions(reorderedOptions);
    //   };
      
    //console.log(selectedBoxOptions);
    return (
        <Box style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left"
        }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                    {(provided) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            display="flex"
                            flexDirection="column"
                            flexWrap="wrap"
                            alignItems="center"
                            gap={1}
                            p={2}
                            border="1px solid #ccc"
                            borderRadius={4}
                        >
                            {selectedBoxOptions.map((value, index) => (
                                <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Chip
                                                key={index}
                                                label={`${value} (${index + 1})`}
                                                onDelete={() => {
                                                    const newSelectedBoxOptions = [...selectedBoxOptions];
                                                    newSelectedBoxOptions.splice(index, 1);
                                                    setSelectedBoxOptions(newSelectedBoxOptions);
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
                <Box style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <InputLabel sx={{fontSize: "0.8rem"}}>Choose Servers</InputLabel>
                    <Select
                        isMulti
                        value={selectedOptions.map((opt, index) => ({
                            label: label[index],
                            value: opt,
                        }))}
                        onChange={handleSelect}
                        onBlur={handleUnfocus}
                        components={{
                            MultiValue: (props) => (
                                <Draggable
                                    draggableId={`draggable-${props.data.value}`}
                                    index={selectedOptions.indexOf(props.data.value)}
                                >
                                    {(provided) => (
                                        <div
                                        
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <components.MultiValue {...props} />
                                        </div>
                                        
                                    )}
                                </Draggable>
                            ),
                            Placeholder: () => null,
                        }}
                        styles={{
                            multiValue: (base) => ({
                                ...base,
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                margin: '2px',
                            }),
                            multiValueLabel: (base) => ({
                                ...base,
                                paddingLeft: '0.5rem',
                            })
                        }}
                        sx={{
                            minWidth: '130px'
                        }}
                    >
                        {options.map((opt, index) => (
                            <MenuItem key={index} value={opt}>
                                {label[index]}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </DragDropContext>
            <Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
                <CancelIcon fontSize="small" color="error" />
            </Button>
        </Box>
    );
}


