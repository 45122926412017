import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import BlockIcon from '@mui/icons-material/Block';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Select } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { group_by_order, find_row_span } from './../../../util/GroupingFunctions.js';

import { useTheme } from '@mui/material';
import './../myTasks/MyTasks.css';
import { getFromTaskBreaker } from './../myTasks/MyTasksDataFetcher.js';

import { UserContext } from '../../../contexts/UserContext.js';
import { TabStateContext } from '../../../contexts/TabStateContext.js';
import { Skeleton } from '@mui/material';
import { GetNameAndEmpID, days_between } from '../../../util/Loading.js';

import { Fade } from '@mui/material';
import AllOutIcon from '@mui/icons-material/AllOut';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { milestone_task_get_query_1, milestone_task_get_query_2, milestone_task_get_query_3, milestone_task_get_query_4 } from '../../../catalystDB/queryGenerator.js';
import { map } from 'd3';

function Tasks({ tasklistId, milestoneName, tasklistName, serverName }) {

	const [searchText, setSearchText] = useState("");

	const [initialRows, setInitialRows] = useState([]);
	const [rows, setRows] = useState([]);
	const [hasData, setHasData] = useState(null);

	const [driList, setDriList] = useState([]);
	const [serversList, setServersList] = useState([]);
	const { userDetails, teamDetails } = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	// console.log(user_email);
	const user_role = teamDetails.current[user_email].Role;
	// console.log(user_role);

	const toggle = useRef(false);

	// useEffect(() => {
	// 	const currentHash = window.location.hash;
	// 	const tasklistnameParam = `tasklistname=["${tasklistName}"]`;
	// 	let newHash = currentHash;

	// 	if (!currentHash.includes(tasklistnameParam)) {
	// 	  if (currentHash.length > 0 && !currentHash.endsWith("&")) {
	// 		newHash += "&";
	// 	  } else if (currentHash.length > 0 && !currentHash.endsWith("#")) {
	// 		newHash += "#";
	// 	  }
	// 	  newHash += tasklistnameParam;
	// 	  window.location.hash = newHash;
	// 	}
	//   }, [tasklistName]);

	// useEffect(() => {
	// 	localStorage.setItem("tasklistName", tasklistName);
	// 	const currentHash = window.location.hash;
	// 	const newHash = `${currentHash}/tasklistdata`;
	// 	window.location.hash = newHash;
	//   }, [tasklistName]);

	//   useEffect(() => {

	// 	const savedTasklistName = localStorage.getItem("tasklistName");

	// 	if (savedTasklistName) {
	// 	  tasklistName = savedTasklistName;
	// 	}
	//   }, []);



	useEffect(() => {
		async function fetchData() {
			// try
			// {
			// 	await setDriDropDown(setDriList, userDetails, teamDetails);
			// 	await setServerDropDown(setServersList, userDetails, teamDetails);
			// }
			// catch(error)
			// {
			// 	console.log(error);
			// }

			try {
				// const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
				const result = await getFromTaskBreaker(milestone_task_get_query_1(tasklistId));
				var data = 0;
				result.map((row, index) => {
					if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
						data++;
						if (data === result.length) {
							toggle.current = true;
						}
					}
				});

				result.sort((a, b) => {
					const timeA = new Date(a[19]);
					const timeB = new Date(b[19]);


					if (timeA < timeB) {
						return -1;
					} else if (timeA > timeB) {
						return 1;
					} else {
						return 0;
					}
				});

				// console.log(result)
				setInitialRows(result);
				setHasData(true);
			}
			catch (error) {
				// console.log(error);
				setHasData(false);
			}
		}
		fetchData();

	}, []);

	var rowsNew = group_by_order(initialRows, [2, 1], 0);

	useEffect(() => {

		setRows(rowsNew);

	}, [initialRows]);

	// var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;
	var content = (
		<>
			<Skeleton variant="rectangular" width={"100%"} height={35} />
			{/* <Skeleton variant="rectangular" width={1600} height={60} /> */}
			<Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
		</>
	);

	rows.sort(priorityCompareFunction);
	const theme = useTheme();

	if (initialRows.length !== 0 && hasData) {
		content = (
			<Fade in={true}>
				<div>
					<MyTasksTopBar rowsCurrent={rowsNew} setRows={(r) => { setRows(r) }} setSearchText={setSearchText} drisList={driList} serversList={serversList.sort()}
						toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} rows={rows} MilestoneName={milestoneName} TasklistName={tasklistName} ServerName={serverName} tasklistId={tasklistId} user_role={user_role} />
					<MyTasksTable toggle={toggle} rows={rows} searchText={searchText} allRows={initialRows} setRows={(r) => { setRows(r) }} tasklistId={tasklistId} user_role={user_role} />
				</div>
			</Fade>
		);
	}
	else if (hasData === false) {
		content = (
			<Fade in={true}>
				<div style={{ color: theme.palette.background.text }}>
					<MyTasksTopBar rowsCurrent={rowsNew} setRows={(r) => setRows(r)} setSearchText={setSearchText} drisList={driList} serversList={serversList.sort()}
						toggle={toggle} setHasData={setHasData} setInitialRows={setInitialRows} userEmail={user_email} MilestoneName={milestoneName} TasklistName={tasklistName} ServerName={serverName} allRows={initialRows} tasklistId={tasklistId} user_role={user_role} />
					<h1>No Tasks Found :(</h1>
				</div>
			</Fade>
		);
	}
	return (
		<div>
			{content}
		</div>
	);
}

function MyTasksTopBar({ toggle, setRows, rowsCurrent, setSearchText, drisList, serversList, setHasData, setInitialRows, userEmail, rows, MilestoneName, TasklistName, ServerName, tasklistId, user_role }) {
	// const drisMenuList = drisList.map((item, index) => {
	// 	return <MenuItem key={index} onClick={() => handleDriItemClick(item)}>{item}</MenuItem>
	// });

	// const serversMenuList = serversList.map((item, index) => {
	// 	return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
	// });
	// console.log("Current user :" + rowsCurrent);
	// console.log("Milestone Name :" + rows);

	const [sortAnchor, setSortAnchor] = useState(null);
	const [openPopup, setOpenPopup] = useState(false);
	// const [ filterAnchor, setFilterAnchor ] = useState(null);
	// const [ driAnchor, setDriAnchor ] = useState(null);
	// const [ serverAnchor, setServerAnchor ] = useState(null);
	const [open, setOpen] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false);

	// useLayoutEffect(() => {
	// 	console.log(rowsCurrent);
	// 	var rowsNew = rowsCurrent.filter(row_ => {
	// 		if(row_[6] === "Closed")
	// 		{
	// 			return false;
	// 		}
	// 		return true;
	// 	});

	// 	setRows(rowsNew);
	// 	toggle.current = false;

	// }, [sortItem]);

	function handleSortButtonClick(event) {
		setSortAnchor(event.currentTarget);
	}

	function handleSortButtonClose() {
		setSortAnchor(null);
	}

	function handleSearchButtonClick() {
		setSearchText("");
		setOpen(!open);
	}

	// function handleFilterButtonClick(event)
	// {
	// 	setFilterAnchor(event.currentTarget);
	// }

	// function handleFilterButtonClose()
	// {
	// 	setFilterAnchor(null);
	// }

	// function handleDriButtonClose()
	// {
	// 	setDriAnchor(null);
	// 	setFilterAnchor(null);
	// }

	// function handleServerButtonClose()
	// {
	// 	setServerAnchor(null);
	// 	setFilterAnchor(null);
	// }

	function handleSortItemClick(item) {
		setSortAnchor(null);
		if (item === "Default") {
			const rowsNew = group_by_order(rowsCurrent, [2, 1], 0);
			setRows(rowsNew);
		}
		else if (item === "Server") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(serverCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "Type") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(typeCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "Priority") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(priorityCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "Status") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(statusCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "TaskName") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(tasknameCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "MilestoneName") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(milestoneNameCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "TasklistName") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(tasklistNameCompareFunction);
			setRows(rowsNew);
		}
		else if (item === "DRI") {
			var rowsNew = [...rowsCurrent];
			rowsNew.sort(driCompareFunction);
			setRows(rowsNew);
		}
	}

	// async function handleFilterItemClick(event, item)
	// {
	// 	if(item === "Server")
	// 	{
	// 		setServerAnchor(event.currentTarget);
	// 	}
	// 	else if(item === "Dri")
	// 	{
	// 		setDriAnchor(event.currentTarget);
	// 	}
	// 	else if(item === "Default")
	// 	{
	// 		try
	// 		{
	// 			setHasData(null);
	// 			const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${userEmail}' AND ReportName= 'TaskBreaker_Data_Report'`);
	// 			setInitialRows(result);
	// 			setHasData(true);		
	// 		}
	// 		catch(error)
	// 		{
	// 			setHasData(false);
	// 		}
	// 	}
	// }


	// async function handleDriItemClick(item)
	// {
	// 	// console.log(item);
	// 	// var rowsNew = rowsCurrent.filter(row_ => {
	// 	// 	if(row_[7] === item)
	// 	// 	{
	// 	// 		return true;
	// 	// 	}
	// 	// 	return false;
	// 	// });
	// 	// setRows(rowsNew);
	// 	try
	// 	{
	// 		setHasData(null);
	// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= '${item}' AND ReportName='TaskBreaker_Data_Report'`);
	// 		setInitialRows(result);
	// 		setHasData(true);		
	// 	}
	// 	catch(error)
	// 	{
	// 		setHasData(false);
	// 	}
	// 	// setDriAnchor(null);
	// 	// setFilterAnchor(null);
	// }

	// async function handleServerItemClick(item)
	// {
	// 	// var rowsNew = rowsCurrent.filter(row_ => {
	// 	// 	if(row_[2] === item)
	// 	// 	{
	// 	// 		return true;
	// 	// 	}
	// 	// 	return false;
	// 	// });
	// 	// setRows(rowsNew);

	// 	try
	// 	{
	// 		setHasData(null);
	// 		const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE Component = '${item}' AND ReportName='TaskBreaker_Data_Report'`);
	// 		// console.log(result);
	// 		setInitialRows(result);
	// 		setHasData(true);		
	// 	}
	// 	catch(error)
	// 	{
	// 		setHasData(false);
	// 	}

	// 	// setServerAnchor(null);
	// 	// setFilterAnchor(null);
	// }

	function handleToggleClosedButtonClick() {
		toggle.current = !toggle.current;

		var rowsNew = rowsCurrent.filter(row_ => {
			if (toggle.current) {
				if (row_[6] === "Closed" || row_[6] === "Cancelled" || row_[6] === "Updated to IDC") {
					return true;
				}
				return false;
			}
			else {
				if (row_[6] === "Closed" || row_[6] === "Cancelled" || row_[6] === "Updated to IDC") {
					return false;
				}
				return true;
			}
		});

		setRows(rowsNew);
	}

	function handleSearchTextChange(event) {
		setSearchText(event.target.value);
	}


	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};


	const handleMenuOpen = (event) => {
		setOpenPopup(true);
		// setAnchorEl(event.currentTarget);
	};

	const HandleClosePopup = () => {
		setOpenPopup(false);
		setIframeLoaded(false);
		async function fetchData() {
			try {
				// const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
				const result = await getFromTaskBreaker(milestone_task_get_query_2(tasklistId));
				//   console.log(result);
				setInitialRows(result);
				setHasData(true);
			} catch (error) {
				// console.log(error);
				setHasData(false);
			}
		}
		fetchData();
	};


	var iframeUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true";


	if (TasklistName.includes("`") || TasklistName.includes("|")) {

		iframeUrl = iframeUrl + "&ParameterPassing=" + MilestoneName + "&Parameter_ServerName=" + ServerName;
	}

	else {
		iframeUrl = iframeUrl + "&ParameterPassing=" + MilestoneName + "&Parameter_ServerName=" + ServerName + "&Parameter_Passing_Tasklist=" + TasklistName;
	}
	// console.log(iframeUrl)

	// https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&ParameterPassing=ZVP-QA_Feature_Milestone_4&Parameter_ServerName=ZVP-QA&Parameter_Passing_Tasklist=Custom audio input
	// https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Report?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&ParameterPassing=ZVP-QA_Feature_Milestone_4&Parameter_ServerName=ZVP-QA&Parameter_Passing_Tasklist=Send tick and wrong icons in functional result message

	return (
		<div className="mytasks-top-bar">
			<div className="mytasks-top-bar-left-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button variant="contained" size="small" onClick={handleMenuOpen}
						sx={{
							borderRadius: "100px",
							padding: 0.9,
							paddingRight: 1.2,
							paddingLeft: 1.2
						}}>
						<AddIcon />
					</Button>
					{/* <Button variant="contained" size="small"><EditNoteIcon /></Button> */}
				</ButtonGroup>
			</div>
			<Dialog open={openPopup} onClose={HandleClosePopup} fullScreen>
				<DialogActions>
					<Button onClick={HandleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!iframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={iframeUrl}
						width="100%"
						height="100%"
						title="External Content"
						onLoad={handleIframeLoad}
						frameBorder={0}
						style={{ display: iframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>
			<div className="mytasks-top-bar-right-group">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
						<TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} autoFocus />
					</Collapse>
					<Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
					{/* <Tooltip title="toggle closed tasks"><Button variant={!toggle.current ? "contained" : "outlined"} size="small" onClick={handleToggleClosedButtonClick}><BlockIcon /></Button></Tooltip> */}
					<Tooltip title={"toggle open/closed tasks"}>
						<Button variant="contained" size="small" onClick={handleToggleClosedButtonClick}>
							{!toggle.current ? <AllOutIcon /> : <BlockIcon />}
							{/* <BlockIcon />s */}
						</Button>
					</Tooltip>
					{/* <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterListIcon/><ArrowDropDownIcon/></Button></Tooltip> */}
					<Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon /><ArrowDropDownIcon /></Button></Tooltip>
				</ButtonGroup>
				<Menu
					anchorEl={sortAnchor}
					open={sortAnchor !== null}
					onClose={handleSortButtonClose}
				>
					<MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Type")}>Type</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("TaskName")}>TaskName</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Priority")}>Priority</MenuItem>
					{/* <MenuItem onClick={() => handleSortItemClick("MilestoneName")}>MilestoneName</MenuItem>
			       <MenuItem onClick={() => handleSortItemClick("TasklistName")}>TasklistName</MenuItem> */}
					<MenuItem onClick={() => handleSortItemClick("Status")}>Status</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("DRI")}>DRI</MenuItem>
				</Menu>
				{/* <Menu
			       anchorEl={filterAnchor}
			       open={filterAnchor !== null}
			       onClose={handleFilterButtonClose}
			    >
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{justifyContent: "center"}}>Default</MenuItem>
				   <MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
				   <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
			    </Menu>
			    <Menu
			       anchorEl={driAnchor}
			       open={driAnchor !== null}
			       onClose={handleDriButtonClose}
			        anchorOrigin={{
			          vertical: "top",
			          horizontal: "left",
			        }}
			        transformOrigin={{
			          vertical: "top",
			          horizontal: "right",
			        }}
			    >
			    	{drisMenuList}
			    </Menu>
			    <Menu
			       anchorEl={serverAnchor}
			       open={serverAnchor !== null}
			       onClose={handleServerButtonClose}
			        anchorOrigin={{
			          vertical: "top",
			          horizontal: "left",
			        }}
			        transformOrigin={{
			          vertical: "top",
			          horizontal: "right",
			        }}
			    >
				    {serversMenuList}
			    </Menu> */}
			</div>
		</div>
	);
}

function MyTasksTable({ toggle, rows, searchText, allRows, setRows, tasklistId, user_role }) {
	const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
	var ind = useRef(0);

	const rowsNew = rows.filter(row_ => {
		if (toggle.current) {
			if (row_[6] === "Closed" || row_[6] === "Cancelled" || row_[6] === "Updated to IDC") {
				return true;
			}
			return false;
		}
		else {
			if (row_[6] === "Closed" || row_[6] === "Cancelled" || row_[6] === "Updated to IDC") {
				return false;
			}
			return true;
		}
	});

	var newRows = rowsNew.filter(row_ => {
		for (const item of row_) {
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

	newRows = newRows.sort((a, b) => {
		const timeA = new Date(a[19]);
		const timeB = new Date(b[19]);


		if (timeA < timeB) {
			return -1;
		} else if (timeA > timeB) {
			return 1;
		} else {
			return 0;
		}
	});
	const theme = useTheme();

	if (newRows.length === 0) {
		return <h1 style={{ color: theme.palette.background.text }}>No Tasks Found :(</h1>;
	}

	const rowSpanInfo = find_row_span(newRows, [2]);
	// console.log(newRows)
	return (
		<div className="mytasks-table">
			<TableContainer component={Paper} className="mytasks-table-container">
				<Table stickyHeader>
					<TableHead>
						<TableRow className="mytasks-table-head">
							<TableCell align="center" className="mytasks-table-grouper-header" />
							<TableCell align="center">S.No.</TableCell>
							<TableCell align="center">Task Name</TableCell>
							{/* <TableCell align="left">Tasklist Name</TableCell>
		            <TableCell align="left">Milestone Name</TableCell> */}
							<TableCell align="center">Priority</TableCell>
							<TableCell align="center">DRI</TableCell>
							<TableCell align="center">Start Date</TableCell>
							<TableCell align="center">{toggle.current ? "Completion Date" : "Due Date"}</TableCell>
							<TableCell align="center">Status</TableCell>
							{/* <TableCell></TableCell> */}


							{/* {toggle.current ? null: (
  user_role !== "Developer" ? (
    <TableCell align="center" className="mytasks-table-grouper-header" />
  ) : null
)} */}

							<TableCell align="center" className="mytasks-table-grouper-header" />
							<TableCell align="center" className="mytasks-table-grouper-header" />
						</TableRow>
					</TableHead>
					<TableBody>
						{newRows.map((row, index) => (
							<Row key={index} row={row} index={index} rowSpanInfo={rowSpanInfo[index]}
								classNameForColors={classNameForColors} ind={ind} allRows={allRows} setRows={setRows} tasklistId={tasklistId} toggle={toggle} user_role={user_role} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

function Row({ row, index, rowSpanInfo, classNameForColors, ind, setRows, allRows, tasklistId, toggle, user_role }) {

	// console.log(user_role);
	var initial_value = row[6];
	const [showAlert1, setShowAlert1] = useState(false);
	const [showAlert2, setShowAlert2] = useState(false);
	const [showAlert3, setShowAlert3] = useState(false);
	const [showAlert4, setShowAlert4] = useState(false);
	const [dependencyDialog, setDependencyDialog] = useState(false);
	const [hasDependency, setHasDependency] = useState(false);
	const [trackLoading, setTrackLoading] = useState(false);
	const [disableSelect, setDisableSelect] = useState(false);

	const [showAlert5, setShowAlert5] = useState(false);


	const [loading, setLoading] = useState(false);
	const { userDetails, teamDetails } = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	const [Total_Count, setTotal_Count] = useState([]);
	const [moreVisibile, setMoreVisible] = useState(false);
	const textBox = useRef();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClickChatLink = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseChatLink = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	function OngoingTaskbreaker() {
		useEffect(() => {
			const fetchValuesData = async () => {
				try {
					const result = await getFromTaskBreaker(milestone_task_get_query_3(allRows, index));
					setTotal_Count(result)
				} catch (error) {

				}
			};

			fetchValuesData();
		}, []);
	}
	OngoingTaskbreaker();

	// console.log()

	// icons
	var priority = "None";
	if (row[3] === "High") {
		priority = <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />;
	}
	else if (row[3] === "Medium") {
		priority = <RemoveCircleTwoToneIcon style={{ color: "orange" }} />;
	}
	else if (row[3] === "Low") {
		priority = <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />;
	}
	else {
		priority = <Brightness1TwoToneIcon style={{ color: "grey" }} />;
	}

	var status = <HexagonIcon style={{ color: "grey", fontSize: "small" }} />;
	if (row[6] === "Open") {


		status = <HexagonIcon style={{ color: "green", fontSize: "small" }} />;
	}
	else if (row[6] === "In Progress") {
		status = <HexagonIcon style={{ color: "royalblue", fontSize: "small" }} />;
	}
	else if (row[6] === "In Review") {
		status = <HexagonIcon style={{ color: "bluegrey", fontSize: "small" }} />;
	}
	else if (row[6] === "To be Tested") {
		status = <HexagonIcon style={{ color: "orange", fontSize: "small" }} />;
	}
	else if (row[6] === "On Hold") {
		status = <HexagonIcon style={{ color: "goldenrod", fontSize: "small" }} />;
	}
	else if (row[6] === "Delayed") {
		status = <HexagonIcon style={{ color: "gold", fontSize: "small" }} />;
	}
	else if (row[6] === "Closed") {
		status = <HexagonIcon style={{ color: "tomato", fontSize: "small" }} />;
	}
	else if (row[6] === "Cancelled") {
		status = <HexagonIcon style={{ color: "navy", fontSize: "small" }} />;
	}
	else if (row[6] === "RMap") {
		status = <HexagonIcon style={{ color: "pink", fontSize: "small" }} />;
	}
	else if (row[6] === "Ongoing") {
		status = <HexagonIcon style={{ color: "salmon", fontSize: "small" }} />;
	}

	var { colorsCache } = useContext(TabStateContext);
	var colorClass = "color-a";
	if (index in colorsCache) {
		colorClass = colorsCache[index];
	}
	else {
		colorClass = classNameForColors[(ind.current) % 5];
		colorsCache[index] = colorClass;
	}

	// side groupers
	var server = (
		<Tooltip title={row[2]} placement="right-start">
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-single-row " + row[2]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[2].length > 8 ? row[2].slice(0, 8) + "..." : row[2]}</div>
			</TableCell>
		</Tooltip>
	);

	if (rowSpanInfo[2] === 0) {
		server = <></>;
	}
	else if (rowSpanInfo[2] !== 1) {
		server = (
			<TableCell align="center" className={"mytasks-table-grouper-component mytasks-multi-row " + row[2]} rowSpan={rowSpanInfo[2]} >
				<div style={{ whiteSpace: "nowrap" }}>{row[2]}</div>
			</TableCell>
		);
		ind.current = ind.current + 1;
	}
	else {
		ind.current = ind.current + 1;
	}

	var type = (
		<TableCell align="center" className={"mytasks-table-grouper-type mytasks-single-row " + row[1]}>
			<div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
		</TableCell>
	);

	if (rowSpanInfo[1] === 0) {
		type = <></>;
	}
	else if (rowSpanInfo[1] !== 1) {
		type = (
			<TableCell align="center" className={"mytasks-table-grouper-type mytasks-multi-row " + row[1]} rowSpan={rowSpanInfo[1]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[1]}</div>
			</TableCell>
		);
	}

	const theme = useTheme();

	var color = "green";

	const today = new Date();
	const startDate = new Date(row[9]);
	const endDate = new Date(row[10]);
	const totDays = days_between(startDate, endDate);

	// console.log(days_between(today, startDate));


	if (today === startDate) {
		color = "green";
	}
	else if (row[6] === "Closed" || row[6] === "Updated to IDC" || row[6] === "Cancelled") {
		if (theme.palette.mode === "dark") {
			color = "White";
		}
		else {
			color = "black";
		}
	}
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "violet";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}

	if (row[10] === "-" || row[9] === "-") {
		color = "";
	}



	function OngoingTaskbreaker() {
		useEffect(() => {
			const fetchValuesData = async () => {
				try {
					const result = await getFromTaskBreaker(milestone_task_get_query_4(allRows, index));
					setTotal_Count(result)
				} catch (error) {
					console.error(error);
				}
			};

			fetchValuesData();
		}, []);
	}
	OngoingTaskbreaker();

	function handleHasDependency(action) {
		if (action !== undefined) {
			const value = textBox.current.value;
			try {
				var tasks = "";
				for (var val of value.split(",")) {
					tasks += val.trim() + ",";
				}
				const newObj = [{
					"DependentTasks": tasks,
					"ROWID": row[17]
				}];
				window.catalyst.table.tableId("TaskBreaker").updateRow(newObj).then(response => {
				}).catch(error => {
				});
				setDependencyDialog(false);
				handleMenuItemClick("On Hold", true);
			} catch (e) {
				alert("Kindly enter proper Task IDs, if more then one exists enter it comma separated");
				return;
			}
		} else {
			setHasDependency(true);
		}
	}

	const handleMenuItemClick = (selectedValue, proceed) => {

		if (trackLoading) {
			return;
		}
		setTrackLoading(true);

		if (proceed === undefined) {
			if (selectedValue === "On Hold") {
				setDependencyDialog(true);
				setHasDependency(false);
			} else {
				const newObj = [{
					"DependentTasks": null,
					"ROWID": row[17]
				}];
				window.catalyst.table.tableId("TaskBreaker").updateRow(newObj).then(response => {
					handleMenuItemClick(selectedValue, true);
				}).catch(error => {
				});
			}
			return;
		}

		function count_ongoing(total, num) {
			if (num[6] == "Ongoing" || num[6] == "In Progress") {
				return total + 1;

			}

			return total;
		}
		// console.log(Total_Count.length)
		// const ongoing_count = allRows.reduce(count_ongoing, 0);
		const ongoing_count = Total_Count.length;
		// if (ongoing_count < 2) {
		setShowAlert4(true);

		setLoading(true);
		//changing the status in creator form 
		const url = '/server/sample?table=StatusUpdate&rowid=' + row[11] + '&Status=' + selectedValue;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,

		})
			.then(response => {

				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Request failed with status: ' + response.status);
				}
			})
			.then(async data => {
				const statusCode = data.StatusCode;
				setTrackLoading(false);
				setDisableSelect(false);
				//console.log(statusCode);


				setLoading(false);
				setShowAlert4(false);
				if (statusCode !== 3000) {
					setShowAlert3(true);
				}
				else {
					// setValue(selectedValue);
					initial_value = selectedValue;

					setShowAlert1(true);
					setShowAlert2(false);
					setShowAlert3(false);


					const rowIndex = allRows.findIndex(row11 => row11.includes(row[11]));

					//console.log(rowIndex);
					//changing specfic index
					const Newallrow = [...allRows];
					Newallrow[rowIndex][6] = selectedValue;
					//console.log("New Array :" + Newallrow);
					try {
						// const result = await getFromTaskBreaker(`SELECT * FROM TaskBreaker WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
						const result = await getFromTaskBreaker(milestone_task_get_query_1(tasklistId));
						var data = 0;
						result.map((row, index) => {
							if (row[6] === 'Closed' || row[6] === 'Updated to IDC' || row[6] === 'Cancelled') {
								data++;
								if (data === result.length) {
									toggle.current = true;
								}
							}
						});

					}
					catch (error) {
						// console.log(error);
					}
					setRows(Newallrow);

				}

			})
			.catch(error => {
				setShowAlert4(false);
				setShowAlert3(true);
				setLoading(false);
				setTrackLoading(false);

			});
	};





	useEffect(() => {
		if (showAlert1) {
			const timer = setTimeout(() => {
				setShowAlert1(false);
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [showAlert1]);

	useEffect(() => {
		if (showAlert2) {
			const timer = setTimeout(() => {
				setShowAlert2(false);
			}, 10000);

			return () => clearTimeout(timer);
		}
	}, [showAlert2]);

	useEffect(() => {
		if (showAlert3) {
			const timer = setTimeout(() => {
				setShowAlert3(false);
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [showAlert3]);

	useEffect(() => {
		if (showAlert5) {
			const timer = setTimeout(() => {
				setShowAlert5(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [showAlert5]);


	const TextOnlyTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
	))(`
          color: black;
          background-color: white;
      `);


	const formatDate = (inputDate) => {
		const months = [
			'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		];

		const date = new Date(inputDate);
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	};
	var formattedDate_new = "";
	if (row[14] === "-" || row[14] === "" || row[14] === null || row[14] === " ") {

	}
	else {
		const inputDate = row[14];
		formattedDate_new = formatDate(inputDate);

	}


	;


	const currentDate = new Date();
	const dueDate = new Date(row[10]);
	const dateDifference = dueDate - currentDate;
	//   console.log(row[16]);

	const [isButtonEnabled, setIsButtonEnabled] = useState(dateDifference <= 5 * 24 * 60 * 60 * 1000 || dateDifference < 0);
	//   console.log(isButtonEnabled);






	const current_email = userDetails.current.auth.email_id;
	// console.log(current_email);

	const handleButtonClick = (event) => {

		setIsButtonEnabled(false)


		// event.preventDefault();
		//  event.target.disabled = true;




		const url = '/server/sample?table=Askforupdate_Method&rowid=' + row[11] + '&AskerEmail=' + current_email;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,

		})
			.then(response => {

				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Request failed with status: ' + response.status);
				}
			})
			.then(data => {
				const statusCode = data.StatusCode;
				//console.log(statusCode);


				setLoading(false);
				setShowAlert4(false);
				if (statusCode !== 3000) {
					setShowAlert3(true);



					setIsButtonEnabled(true)
				}
				else {
					// setValue(selectedValue);


					setShowAlert5(true);



					setIsButtonEnabled(true)

				}

			})
			.catch(error => {
				console.error(error);

				setShowAlert3(true);



				setIsButtonEnabled(true)

			});




	};







	// console.log(row[20]);






	return (
		<TableRow
			hover={true}
			sx={{
				'& > *': { borderBottom: 'unset' },
				backgroundColor: theme.palette.background.tableRow,
				'&:hover': {
					backgroundColor: theme.palette.background.tableRowHover + " !important"
				}
			}}
			onMouseEnter={() => setMoreVisible(true)}
			onMouseLeave={() => setMoreVisible(false)}
		>
			{server}
			<TableCell align="center" style={{ width: "2.5vw", maxWidth: "2.5vw" }}>{index + 1}</TableCell>
			{/* <TableCell align="left" style={{maxWidth: "20vw", wordWrap: "break-word"}}>
				<Link to={row[8]} target='_blank' style={{textDecoration: "none",
														  color: "inherit"}} 
												    onMouseEnter={(event) => {
													    event.target.style.color = theme.palette.primary.main;
												    }}
												    onMouseLeave={(event) => {
														event.target.style.color = "inherit";
												    }}>
					{row[0]}
				</Link>
			</TableCell> */}

			{["", " ", undefined, null].some(value => row[12] === value) ? (

				<TableCell align="center" style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
					<Link to={row[8]} target='_blank' style={{
						textDecoration: "none",
						color: "inherit"
					}}
						onMouseEnter={(event) => {
							event.target.style.color = theme.palette.primary.main;
						}}
						onMouseLeave={(event) => {
							event.target.style.color = "inherit";
						}}>
						{row[0]}
					</Link>
					{/* {row[0]} */}
				</TableCell>

			) : (


				<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{row[12]} </span>} followCursor >
					<TableCell align="center" style={{ maxWidth: "20vw", wordWrap: "break-word" }}>
						<Link to={row[8]} target='_blank' style={{
							textDecoration: "none",
							color: "inherit"
						}}
							onMouseEnter={(event) => {
								event.target.style.color = theme.palette.primary.main;
							}}
							onMouseLeave={(event) => {
								event.target.style.color = "inherit";
							}}>
							{row[0]}
						</Link>
						{/* {row[0]} */}
					</TableCell>


				</TextOnlyTooltip>

			)}


			<TableCell align="center"><Box className="flex-column">{priority}<p>{row[3]}</p></Box></TableCell>
			<TableCell align="center" style={{ width: "10vw" }}><GetNameAndEmpID emails={row[7]} /></TableCell>
			<TableCell align="center" style={{ width: "6vw", minWidth: "5vw", hyphens: "none" }}>{row[9]}</TableCell>
			<TableCell align="center" style={{ width: "6vw", minWidth: "5vw", hyphens: "none", color: color }}>





				{
					row[6] === 'Completed' || row[6] === 'Cancelled' || row[6] === 'Updated to IDC' || row[6] === 'Closed' ?

						<Box>
							<Box sx={{
								fontSize: "0.9rem"
							}}>


								{formattedDate_new === "" || formattedDate_new === null ? "-" : formattedDate_new}
							</Box>
							<Box sx={{
								fontSize: "0.6rem",
								color: theme.palette.background.text
							}}>

								{[undefined, "", " ", null, "-"].includes(row[10]) || [undefined, "", " ", null, "-"].includes(row[14]) ? "" : "DueDate :" + row[10]}

							</Box>
						</Box>

						:


						row[10]
				}

			</TableCell>
			{/* <TableCell align="center"><Box className="flex-row">{status}<p>{row[6]}</p></Box></TableCell> */}
			<TableCell style={{ width: "8vw" }} align="center">
				{/* { row[16] != "-" ? (<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins' ,fontSize: '0.875rem', color: "Black" ,backgroundImage:"" }}>{ row[16]} </span>}  followCursor > */}
				<Select style={{ width: "8vw" }} disabled={trackLoading || disableSelect} value={initial_value} sx={{ marginTop: 1, width: 150, height: 28 }}>
					<MenuItem value={'Ongoing'} onClick={() => handleMenuItemClick('Ongoing')} >Ongoing</MenuItem>
					<MenuItem value={'Open'} onClick={() => handleMenuItemClick('Open')} >Open</MenuItem>
					<MenuItem value={'In Progress'} onClick={() => handleMenuItemClick('In Progress')} >In Progress</MenuItem>
					<MenuItem value={'In Review'} onClick={() => handleMenuItemClick('In Review')} >In Review</MenuItem>
					<MenuItem value={'To be Tested'} onClick={() => handleMenuItemClick('To be Tested')} >To be Tested</MenuItem>
					<MenuItem value={'Delayed'} onClick={() => handleMenuItemClick('Delayed')} >Delayed</MenuItem>
					<MenuItem value={'Cancelled'} onClick={() => handleMenuItemClick('Cancelled')} >Cancelled</MenuItem>
					<MenuItem value={'RMap'} onClick={() => handleMenuItemClick('RMap')} >RMap</MenuItem>
					<MenuItem value={'On Hold'} onClick={() => handleMenuItemClick('On Hold')} >On Hold</MenuItem>
					<MenuItem value={'Closed'} onClick={() => handleMenuItemClick('Closed')} >Completed</MenuItem>
					<MenuItem value={'Updated to IDC'} onClick={() => handleMenuItemClick('Updated to IDC')} >Updated to IDC</MenuItem>

				</Select>
				<Dialog
					open={dependencyDialog}
					onClose={() => setDependencyDialog(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{hasDependency
							? "Dependencies"
							: "Does this Task have any Dependent Tasks?"}
					</DialogTitle>
					<DialogContent>
						{hasDependency ? (
							<DialogContentText id="alert-dialog-description">
								Enter the Dependent Tasks ID's (comma separated)
							</DialogContentText>
						) : null}
						{hasDependency ? (
							<textarea
								style={{
									marginTop: "20px",
									width: "92%",
									height: "100px",
									border: "1px solid black",
									backgroundColor: theme.palette.background.infoDivOuter,
									color: theme.palette.background.text
								}}
								ref={textBox}
							>
								{row[16]}
							</textarea>
						) : null}
					</DialogContent>
					<DialogActions>
						{!hasDependency ? (
							<>
								<Button onClick={() => {
									setHasDependency(false);
									setDependencyDialog(false);
								}}>
									Cancel
								</Button>
								<Button
									onClick={() => {
										handleMenuItemClick("On Hold", true);
										setDependencyDialog(false);
										setHasDependency(false);
									}}
								>
									No
								</Button>
								<Button onClick={() => handleHasDependency()} autoFocus>
									Yes
								</Button>
							</>
						) : (
							<>
								<Button onClick={() => {
									setDependencyDialog(false);
									// setHasDependency(false);
								}}>
									Cancel
								</Button>
								<Button onClick={() => {
									handleHasDependency("submit");
								}}>
									Submit
								</Button>
							</>
						)}
					</DialogActions>
				</Dialog>
				<Snackbar
					open={showAlert1}

					onClose={() => setShowAlert1(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="success">Status Updated 👍 </Alert>
				</Snackbar>

				<Snackbar
					open={showAlert2}

					onClose={() => setShowAlert2(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="error">Please update the status of the current ongoing tasks and attempt to set the new task to the ongoing state.</Alert>

				</Snackbar>

				<Snackbar
					open={showAlert3}

					onClose={() => setShowAlert3(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="warning">try after some time</Alert>
				</Snackbar>

				<Snackbar
					open={showAlert4}

					onClose={() => setShowAlert4(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="info">Updating..</Alert>
				</Snackbar>


				<Snackbar
					open={showAlert5}

					onClose={() => setShowAlert5(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="info">Asked for update Successfully 👍 </Alert>
				</Snackbar>


			</TableCell>
			{/* <TableCell align="center" style={{ width: "10vw", minWidth: "6vw", hyphens: "none" }}>
				{row[20] === "-" || row[20] === undefined ? (
					<Button disabled variant="contained" onClick={handleClickChatLink}>
					   Open Chat Link
				    </Button> 
				):(
					<a key={index}
					href={row[20]}
					target="_blank"
					rel="noopener noreferrer"
					style={{ textDecoration: "none", color: "inherit" }}
					sx={{ p: 2 }}>
					<Button variant="contained" onClick={handleClickChatLink}>
					   Open Chat Link
					</Button> 
					</a>
				
				)} */}

			{/* <Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleCloseChatLink}
				anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
				}}
			>
				<a key={index}
					href={row[20]}
					target="_blank"
					rel="noopener noreferrer"
					style={{ textDecoration: "none", color: "inherit" }}
					sx={{ p: 2 }}>

          <Box  
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#f0f7fa" : "#425c63",
            height: "100%",
            minWidth: "200px",
            borderRadius: "10px",
            // padding: "10px",
            boxSizing: "border-box",
            fontSize: "0.7rem",
            display: "flex",
            flexDirection: "column",
            // gap: "10px",
            cursor: "pointer"
          }}
        >
          <Box
            sx={{
              flexGrow: 9,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: '3px'
            }}
          >
            <div>
              <img src={cliq_img} style={{ paddingLeft: '10px'}} height={30} alt={row[20]}/>
            </div>

            <div style={{fontSize: '15px', paddingLeft: '20px',  textOverflow: "ellipsis"}}>
              
              {row[21]}
            </div>

          </Box>
        </Box>

				</a>
			</Popover> */}
			{/* </TableCell>  */}



			{/* {toggle.current ? (
			null
			
		) : user_role !== 'Developer' && (
			<TableCell></TableCell>
			 
		)} */}





			<MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} creatorRowID={row[13]} MilestoneUniqueID1={row[15]} handleButtonClick={handleButtonClick} isButtonEnabled={isButtonEnabled} ChatLink={row[20]}
						 handleClickChatLink={handleClickChatLink} index={index} catalystRowId ={row[17]} commentsData={row[22]} TaskName={row[0]} />
			{type}

		</TableRow>
	);

}

function MoreSection({ moreVisibile, setMoreVisible, creatorRowID, MilestoneUniqueID1, handleButtonClick, isButtonEnabled, ChatLink, handleClickChatLink, index, catalystRowId, commentsData, TaskName }) {
	const [moreAnchor, setMoreAnchor] = useState(null);
	const [editIframeLoaded, setEditIframeLoaded] = useState(false);
	const [showAlert7, setShowAlert7] = useState(false);
	const [copiedText, setCopiedText] = useState('');
	const [openPopupEdit, setOpenPopupEdit] = useState(false);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [viewCommentsOpen, setViewCommentsOpen] = useState(false);
	const { userDetails } = useContext(UserContext);
	const user_email = userDetails.current.auth.email_id;
	const [comments, setComments] = useState(
	  commentsData === "-" ? [] : JSON.parse(commentsData)
	);
  	const [isViewingComments, setIsViewingComments] = useState(false);
	// console.log(commentsWithoutFilter)
	// console.log(comments)
	

	const milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/Report/record-edit/All_Reports/" + creatorRowID + "/";
	// https://creatorapp.zoho.com/zohointranet/zvp-team/Report/record-edit/All_Reports/363463000947934204/

	const handleClosePopupEdit = () => {
		setOpenPopupEdit(false);
		setEditIframeLoaded(false);
		setMoreVisible(false);
	};

	const handleEditIframeLoad = () => {
		setEditIframeLoaded(true);
	};

	function handleEditClick() {
		setOpenPopupEdit(true);
		setMoreAnchor(null);
	}

	const MilestoneUniqueID = MilestoneUniqueID1;

	const handleCopy = async () => {
		try {
			setShowAlert7(true);
			await navigator.clipboard.writeText(MilestoneUniqueID);
			setCopiedText(MilestoneUniqueID);
		} catch (err) {
			console.error('Failed to copy text: ', err);
		}
	};


	useEffect(() => {
		if (showAlert7) {
			const timer = setTimeout(() => {
				setShowAlert7(false);
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [showAlert7]);

	const handleAddComment = () => {
		if (newComment.trim()) {
		  const newCommentObj = {
			name: user_email,
			text: newComment,
			timestamp: new Date().toLocaleString(),
		  };
		  setComments((prevComments) => [...prevComments, newCommentObj]);
		  setNewComment("");
		//   setCommentDialogOpen(false);
		  const updatedCommentsData = [...comments, newCommentObj];
		  const detailsSet = [{ commentsData: JSON.stringify(updatedCommentsData), ROWID: catalystRowId }];
	
		  const datastore = window.catalyst.table;
		  const table = datastore.tableId("5781000006856839");
	
		  table.updateRow(detailsSet).then(/*log*/).catch(console.error);
		}
	  };

	  
	return (
		<TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
			<Fade in={moreVisibile} timeout={100}>
				<MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
			</Fade>
			<Menu anchorEl={moreAnchor}
				open={moreAnchor !== null}
				onClose={() => { setMoreAnchor(null) }}
			>
				<MenuItem onClick={handleEditClick}>
					<Box style={{ display: "flex" }}>
						<EditIcon fontSize="small" />
						&nbsp;Edit
					</Box>
				</MenuItem>
				<Tooltip title={MilestoneUniqueID} placement="right-start">
					<MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						{/* <TableCell align="center" style={{ width: "10vw", minWidth: "6vw", hyphens: "none" }}> */}
						<div style={{ marginRight: '6px' }} onClick={handleCopy}  >Task UniqueID</div>
						<ContentCopyIcon variant="contained" color="primary" onClick={handleCopy} style={{ fontSize: '17px' }}></ContentCopyIcon>
						{/* <Button sx={{ marginRight: '6px' }} variant="contained" disabled={!isButtonEnabled} onClick={handleButtonClick}> Ask for update</Button> */}
						{/* </TableCell> */}
					</MenuItem>
				</Tooltip>
				<MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{/* <TableCell align="center" style={{ width: "10vw", minWidth: "6vw", hyphens: "none" }}> */}
					{/* <div style={{ marginRight: '6px' }}>Task UniqueID</div>
	<ContentCopyIcon variant="contained" color="primary" onClick={handleCopy}   style={{ fontSize: '17px' }}></ContentCopyIcon> */}
					<Button sx={{ marginRight: '6px' }} variant="contained" disabled={!isButtonEnabled} onClick={handleButtonClick}> Ask for update</Button>
					{/* </TableCell> */}
				</MenuItem>
				<MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{ChatLink === "-" || ChatLink === undefined ? (
						null
					) : (
						<a key={index}
							href={ChatLink}
							target="_blank"
							rel="noopener noreferrer"
							style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}
						>
							<div onClick={handleClickChatLink}>Open Chat Link</div>
							<ForumRoundedIcon onClick={handleClickChatLink} fontSize="medium" style={{ marginLeft: '18px', fontSize: '17px' }} />
						</a>
					)}
				</MenuItem>
				<MenuItem onClick={() => setCommentDialogOpen(true)}>
					Comments
				</MenuItem>
				{/* <MenuItem onClick={() => setCommentDialogOpen(true)}>Add Comment</MenuItem>
		  <MenuItem onClick={() => setViewCommentsOpen(true)}>View Comments</MenuItem> */}
		</Menu>
  
		<Dialog 
			sx={{width:"1000px",margin:"auto"}}
			maxWidth={false}       
			open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)}
		>
        <DialogTitle sx={{margin:"auto"}}>{TaskName}</DialogTitle>
        <DialogContent sx={{ padding: 0, height: '100%' }}>

          {/* Sticky Text Field */}
          <Box sx={{
            position: 'sticky', top: 0, backgroundColor: 'white', padding: 2, zIndex: 1,
          }}>
            <TextField
              fullWidth
              multiline
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Write your comment here..."
              rows={4}
              sx={{
                '& .MuiInputBase-root': {
                  height: '100%',
                  alignItems: 'flex-start',
                },
              }}
            />
            <Button
              onClick={handleAddComment}
              color="primary"
              variant="contained"
              disabled={!newComment.trim()}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Add Comment
            </Button>
          </Box>

          {/* Scrollable Comments List */}
          <Box sx={{
            height: 'calc(100% - 180px)', // Adjust height to account for sticky text field and button
            overflowY: 'auto',
            padding: 2,
          }}>
            {comments.length === 0 ? (
              <Typography>No comments yet. Be the first to add one!</Typography>
            ) : (
              comments.map((comment, idx) => (
                <Box key={idx} display="flex" alignItems="center" mb={2}>
                  <PersonIcon style={{ marginRight: '8px' }} />
                  <Box>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {comment.name}{' '}
                      <span style={{ fontSize: '0.8rem' }}>
                        ({comment.timestamp})
                      </span>
                    </Typography>
                    <Typography variant="body2">{comment.text}</Typography>
                  </Box>
                </Box>
              ))
            )}
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommentDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
		<Dialog open={viewCommentsOpen} onClose={() => setViewCommentsOpen(false)}>
		  <DialogTitle>Comments</DialogTitle>
		  <DialogContent>
		  {comments.length === 0 ? (
			  <Typography>No comments yet. Be the first to add one!</Typography>
			) : (
			  comments.map((comment, idx) => (
				<Box key={idx} display="flex" alignItems="center" mb={2}>
				  <PersonIcon style={{ marginRight: "8px" }} />
				  <Box>
					<Typography variant="subtitle2" fontWeight="bold">
					  {comment["name"]}{" "}
					  <span style={{ fontSize: "0.8rem" }}>
						({comment["timestamp"]})
					  </span>
					</Typography>
					<Typography variant="body2">{comment["text"]}</Typography>
				  </Box>
				</Box>
			  ))
			)}
		  </DialogContent>
		  <DialogActions>
			<Button onClick={() => setViewCommentsOpen(false)}>Close</Button>
		  </DialogActions>
		</Dialog>
			<Dialog open={openPopupEdit} onClose={handleClosePopupEdit} fullScreen>
				<DialogActions>
					<Button onClick={handleClosePopupEdit} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!editIframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={milestoneEditUrl}
						width="100%"
						height="100%"
						title="External Content"
						frameBorder={0}
						onLoad={handleEditIframeLoad}
						style={{ display: editIframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>
			<Snackbar
				open={showAlert7}

				onClose={() => setShowAlert7(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success">Task UniqueID copied to clipboard 👍 </Alert>
			</Snackbar>
		</TableCell>
	);
}
export default Tasks;

// ####################################################### util functions ###################################################################

function priorityCompareFunction(a, b) {
	const power = {
		"High": 3,
		"Medium": 2,
		"Low": 1,
		"None": 0
	};

	if (power[a[3]] < power[b[3]]) {
		return 1;
	}
	if (power[a[3]] > power[b[3]]) {
		return -1;
	}
	return 0;
}


function statusCompareFunction(a, b) {
	const power = {
		"Updated to IDC": 10,
		"Open": 9,
		"In Progress": 8,
		"Ongoing": 7,
		"To be Tested": 6,
		"In Review": 5,
		"On Hold": 4,
		"Delayed": 3,
		"Closed": 2,
		"Cancelled": 1,
		"Rmap": 0
	};

	if (power[a[6]] < power[b[6]]) {
		return 1;
	}
	if (power[a[6]] > power[b[6]]) {
		return -1;
	}
	return 0;
}

function serverCompareFunction(a, b) {
	if (a[2] < b[2]) {
		return -1;
	}
	if (a[2] > b[2]) {
		return 1;
	}
	return 0;
}

function typeCompareFunction(a, b) {
	if (a[1] < b[1]) {
		return -1;
	}
	if (a[1] > b[1]) {
		return 1;
	}
	return 0;
}

function tasknameCompareFunction(a, b) {
	if (a[0] < b[0]) {
		return -1;
	}
	else if (b[0] < a[0]) {
		return 1;
	}
	return 0;
};

function milestoneNameCompareFunction(a, b) {
	if (a[4] < b[4]) {
		return -1;
	}
	else if (b[4] < a[4]) {
		return 1;
	}
	return 0;
}

function tasklistNameCompareFunction(a, b) {
	if (a[5] < b[5]) {
		return -1;
	}
	else if (b[5] < a[5]) {
		return 1;
	}
	return 0;
}

function driCompareFunction(a, b) {
	if (a[7] < b[7]) {
		return -1;
	}
	else if (b[7] < a[7]) {
		return 1;
	}
	return 0;
}

// async function setDriDropDown(setDriList, userDetails, teamDetails)
// {
// 	return new Promise((resolve, reject) => {
// 		// console.log(teamDetails);
// 		const userEmail = userDetails.current.auth.email_id;
// 		const userRole = teamDetails.current[userEmail].Role;

// 		if(userRole === "CEO")
// 		{
// 			var users = [];
// 			for(const user in teamDetails.current)
// 			{
// 				users.push(user);
// 			}
// 			users.sort();
// 			setDriList(users);
// 		}
// 		else
// 		{
// 			var users = findMentees([userEmail], teamDetails);
// 			users.sort();
// 			users = [userEmail, ...users];
// 			setDriList(users);
// 		}	
// 		return resolve();	
// 	});
// }


// async function setServerDropDown(setServersList, userDetails, teamDetails)
// {
// 	return new Promise((resolve, reject) => {
// 		// console.log(teamDetails);
// 		const userEmail = userDetails.current.auth.email_id;
// 		const userRole = teamDetails.current[userEmail].Role;

// 		if(userRole === "CEO")
// 		{
// 			setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
// 		}
// 		else
// 		{
// 			setServersList(teamDetails.current[userEmail].Servers.split(","));
// 		}	
// 		return resolve();	
// 	});
// }
// 
// function findMentees(userEmails, teamDetails)
// {
// 	if(userEmails.length === 0)
// 	{
// 		return [];
// 	}

// 	const arr = [];

// 	for(const person in teamDetails.current)
// 	{
// 		if(userEmails.includes(teamDetails.current[person].ReportingTo))
// 		{
// 			arr.push(person);
// 		}
// 	}

// 	const newArr = arr.concat(findMentees(arr, teamDetails));

// 	return newArr;
// }
