import { MILESTONE_DATA_FETCHER_1_DEFAULT, MILESTONE_DATA_FETCHER_2_DEFAULT } from "../../../catalystDB/queryGenerator";
import { queryDB } from "../../../util/QueryDB";

export function getFromTaskDetails(queryString = MILESTONE_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);
			// console.log(response.content.length);

			var data = [];
			var table = {};
			for(const item of response)
			{
				const row = item.TaskDetails;
		
				if(row.MilestoneId in table)
				{
					if([undefined, "", " ", null].includes(row.MilestoneDueDate))
					{
						continue;
					}
					else
					{
						table[row.MilestoneId] = row;
					}
				}
				else
				{
					table[row.MilestoneId] = row;
				}

			}

			for(const item in table)
			{
				const row = table[item];
				//console.log(row);
				const result = [
					row.Component,
					[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
					[undefined, "", " ", null].includes(row.MilestoneStartDate) ? "-" : row.MilestoneStartDate,
					[undefined, "", " ", null].includes(row.MilestoneDueDate) ? [undefined, "", " ", null].includes(row.RevertDate) ? "-" : row.RevertDate +" (Expiry Date) " : row.MilestoneDueDate,
					[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
					[undefined, "", " ", null].includes(row.Email) ? "-" : row.Email,
					[undefined, "", " ", null].includes(row.Serverlist) ? "-" : row.Serverlist.replaceAll("[", "").replaceAll("]", ""),
					[undefined, "", " ", null].includes(row.MilestoneStatus) ? "#" : row.MilestoneStatus,
					row.MilestoneId,
					row.Milestone_Rowid,
					row.MilestoneDescription,
					[undefined, "", " ", null].includes(row.MilestoneCompletionDate) ? "-" : row.MilestoneCompletionDate,
					[undefined, "", " ", null].includes(row.Custom_Milestone_Name) ? "-" : row.Custom_Milestone_Name

		
				];
				data.push(result);
			}
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		})

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);
		// 			// console.log(response.content.length);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			var data = [];
		// 			var table = {};
		// 			for(const item of response.content)
		// 			{
		// 				const row = item.TaskDetails;
				
		// 				if(row.MilestoneId in table)
		// 				{
		// 					if([undefined, "", " ", null].includes(row.MilestoneDueDate))
		// 					{
		// 						continue;
		// 					}
		// 					else
		// 					{
		// 						table[row.MilestoneId] = row;
		// 					}
		// 				}
		// 				else
		// 				{
		// 					table[row.MilestoneId] = row;
		// 				}

		// 			}

		// 			for(const item in table)
		// 			{
		// 				const row = table[item];
		// 				const result = [
		// 					row.Component,
		// 					[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
		// 					[undefined, "", " ", null].includes(row.MilestoneStartDate) ? "-" : row.MilestoneStartDate,
		// 					// [undefined, "", " ", null].includes(row.MilestoneDueDate) ? "-" : row.MilestoneDueDate,
		// 					[undefined, "", " ", null].includes(row.MilestoneDueDate) ? [undefined, "", " ", null].includes(row.RevertDate) ? "-" : row.RevertDate +" (Expiry Date) " : row.MilestoneDueDate,
		// 					[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
		// 					[undefined, "", " ", null].includes(row.Email) ? "-" : row.Email,
		// 					[undefined, "", " ", null].includes(row.Serverlist) ? "-" : row.Serverlist.replaceAll("[", "").replaceAll("]", ""),
		// 					[undefined, "", " ", null].includes(row.MilestoneStatus) ? "#" : row.MilestoneStatus,
		// 					row.MilestoneId,
		// 					row.Milestone_Rowid,
		// 					row.MilestoneDescription
		// 				];
		// 				data.push(result);
		// 			}
		// 			if(data.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			resolve(data);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}

export function milestoneTransformFunction(response) {

	var data = [];
	var table = {};
	for(const item of response)
	{
		const row = item.TaskDetails;

		// if(row.MilestoneId in table)
		// {
		// 	if([undefined, "", " ", null].includes(row.MilestoneDueDate))
		// 	{
		// 		continue;
		// 	}
		// 	else
		// 	{
		// 		table[row.MilestoneId] = row;
		// 	}
		// }
		// else
		// {
		// 	table[row.MilestoneId] = row;
		// }
		table[row.MilestoneId] = row;

	}

	for(const item in table)
	{
		const row = table[item];
		//console.log(row);
		const result = [
			row.Component,
			[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
			[undefined, "", " ", null].includes(row.MilestoneStartDate) ? "-" : row.MilestoneStartDate,
			[undefined, "", " ", null].includes(row.MilestoneDueDate) ? [undefined, "", " ", null].includes(row.RevertDate) ? "-" : row.RevertDate +" (Expiry Date) " : row.MilestoneDueDate,
			[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
			[undefined, "", " ", null].includes(row.Email) ? "-" : row.Email,
			[undefined, "", " ", null].includes(row.Serverlist) ? "-" : row.Serverlist.replaceAll("[", "").replaceAll("]", ""),
			[undefined, "", " ", null].includes(row.MilestoneStatus) ? "#" : row.MilestoneStatus,
			row.MilestoneId,
			row.Milestone_Rowid,
			row.MilestoneDescription,
			[undefined, "", " ", null].includes(row.MilestoneCompletionDate) ? "-" : row.MilestoneCompletionDate,
			[undefined, "", " ", null].includes(row.MilestoneUniqueID) ? "-" : row.MilestoneUniqueID,
			[undefined, "", " ", null].includes(row.TasklistUniqueID) ? "-" : row.TasklistUniqueID,
			row.CreatorRowid,
			[undefined, "", " ", null].includes(row.TasklistStatus) ? "-" : row.TasklistStatus,
			[undefined, "", " ", null].includes(row.Custom_Milestone_Name) ? "-" : row.Custom_Milestone_Name,
			[undefined, "", " ", null].includes(row.Approval_Sent_To) ? "-" : row.Approval_Sent_To,
			[undefined, "", " ", null].includes(row.Approval_Status) ? "-" : row.Approval_Status,
			[undefined, "", " ", null].includes(row.CreatedFromNewMilestone) ? "-" : row.CreatedFromNewMilestone,
			[undefined, "", " ", null].includes(row.Tasklistid) ? "-" : row.Tasklistid,
			[undefined, "", " ", null].includes(row.MilestoneFileID) ? "-" : row.MilestoneFileID

			
		];
		data.push(result);
	}
	if(data.length === 0)
	{
		return undefined;
	}
	//console.log(data);
	return data;
}

var tasklistCache = {};

export function getFromTaskDetailsForTasklists(queryString = MILESTONE_DATA_FETCHER_1_DEFAULT, disableCache=false)
{
	if(queryString in tasklistCache && !disableCache)
	{
		return tasklistCache[queryString];
	}
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);

			var data = [];
			for(const item of response)
			{
				const row = item.TaskDetails;

				const result = [
					row.TasklistName,
					[undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
					[undefined, "", " ", null].includes(row.TasklistStartDate) ? "-" : row.TasklistStartDate,
					[undefined, "", " ", null].includes(row.TasklistDueDate) ? "-" : row.TasklistDueDate,
					[undefined, "", " ", null].includes(row.TasklistPriority) ? "None" : row.TasklistPriority,
					[undefined, "", " ", null].includes(row.TasklistStatus) ? "-" : row.TasklistStatus,
					row.Tasklistid,
					row.Tasklisturl,
					row.TasklistDescription,
					row.Component,
					[undefined, "", " ", null].includes(row.TasklistCompletionDate) ? "-" : row.TasklistCompletionDate,
					[undefined, "", " ", null].includes(row.TasklistUniqueID) ? "-" : row.TasklistUniqueID,
					[undefined, "", " ", null].includes(row.TasklistUniqueID) ? "-" : row.CREATEDTIME,
					[undefined, "", " ", null].includes(row.Chat_Url) ? "-" : row.Chat_Url,
					[undefined, "", " ", null].includes(row.Chat_Description) ? "-" : row.Chat_Description,
					row.IsTasklistDeleted,
					row.CreatorRowid,
					row.ROWID
				];
				data.push(result);
			}
			if(data.length === 0)
			{
				reject("none");
			}
			tasklistCache[queryString] = data;
			resolve(data);
		}).catch((error) => {
			reject(error);
		});

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			var data = [];
		// 			for(const item of response.content)
		// 			{
		// 				const row = item.TaskDetails;

		// 				const result = [
		// 					row.TasklistName,
		// 					[undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
		// 					[undefined, "", " ", null].includes(row.TasklistStartDate) ? "-" : row.TasklistStartDate,
		// 					[undefined, "", " ", null].includes(row.TasklistDueDate) ? "-" : row.TasklistDueDate,
		// 					[undefined, "", " ", null].includes(row.TasklistPriority) ? "None" : row.TasklistPriority,
		// 					[undefined, "", " ", null].includes(row.TasklistStatus) ? "-" : row.TasklistStatus,
		// 					row.Tasklistid,
		// 					row.Tasklisturl,
		// 					row.TasklistDescription,
		// 					row.Component
		// 				];
		// 				data.push(result);
		// 			}
		// 			if(data.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			tasklistCache[queryString] = data;
		// 			resolve(data);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}

var tasksCache = {};

export function getTasks(queryString = MILESTONE_DATA_FETCHER_2_DEFAULT)
{
	if(queryString in tasksCache)
	{
		return tasksCache[queryString];
	}
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);

			if(response.length === 0)
			{
				reject("none");
			}
			tasksCache[queryString] = response.length;
			resolve(response.length);
		}).catch((error) => {
			reject(error);
		});

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			if(response.content.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			tasksCache[queryString] = response.content.length;
		// 			resolve(response.content.length);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}