import { MY_TASKS_DATA_FETCHER_1_DEFAULT } from "../../../catalystDB/queryGenerator";
import { queryDB } from "../../../util/QueryDB";

export function getFromTaskBreaker(queryString = MY_TASKS_DATA_FETCHER_1_DEFAULT)
{
	return new Promise((resolve, reject) => 
	{
		const start = global.performance.now();

		queryDB(queryString).then((response) => {
			// console.log(response);
			const end = global.performance.now();
			// console.log(`took ${end-start} ms`);

			var data = [];
			for(const item of response)
			{
				const row = item.TaskBreaker;
				const result = [
					row.TaskName,
					[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
					[undefined, "", " ", null].includes(row.Component) ? "-" : row.Component,
					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
					[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
					[undefined, "", " ", null].includes(row.TaskListName) ? "-" : row.TaskListName,
					[undefined, "", " ", null].includes(row.TaskStatus) ? "-" : row.TaskStatus,
					[undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
					[undefined, "", " ", null].includes(row.TaskUrl) ? "#" : row.TaskUrl,
					[undefined, "", " ", null].includes(row.TaskStartDate) ? "-" : row.TaskStartDate,
					[undefined, "", " ", null].includes(row.TaskDueDate) ? "-" : row.TaskDueDate,
					[undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CreatorRowid,
					row.TaskDescription,
					[undefined, "", " ", null].includes(row.TaskBreakerRoadmapRowid) ? "-" : row.TaskBreakerRoadmapRowid,
					[undefined, "", " "].includes(row.TaskCompletionDate) ? "-" : row.TaskCompletionDate,
					[undefined, "", " ",null].includes(row.TaskUniqueID) ? "-" : row.TaskUniqueID,
					[undefined, "", " ", null].includes(row.DependentTasks) ? undefined : row.DependentTasks,
					[undefined, "", " ", null].includes(row.ROWID) ? undefined : row.ROWID,
					[undefined, "", " ", null].includes(row.ReportingTo) ? undefined : row.ReportingTo,
					[undefined, "", " ", null].includes(row.CREATEDTIME) ? undefined : row.CREATEDTIME,
					[undefined, "", " ", null].includes(row.RaoadMap_Chat_Url) ? undefined : row.RaoadMap_Chat_Url,
					[undefined, "", " ", null].includes(row.RoadMap_Chat_Description) ? undefined : row.RoadMap_Chat_Description,
					[undefined, "", " ", null].includes(row.commentsData) ? "-" : row.commentsData

					
				];
				data.push(result);
			}
			if(data.length === 0)
			{
				reject("none");
			}
			resolve(data);
		}).catch((error) => {
			reject(error);
		})

		// var zcql = window.catalyst.ZCatalystQL;
		// var zcqlPromise  = zcql.executeQuery(queryString);
		// zcqlPromise
		// 		.then((response) => {
		// 			// console.log(response);
		// 			const end = global.performance.now();
		// 			// console.log(`took ${end-start} ms`);

		// 			if(response.status === 400)
		// 			{
		// 				reject("error");
		// 			}
	
		// 			var data = [];
		// 			for(const item of response.content)
		// 			{
		// 				const row = item.TaskBreaker;
		// 				const result = [
		// 					row.TaskName,
		// 					[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
		// 					[undefined, "", " ", null].includes(row.Component) ? "-" : row.Component,
		// 					!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
		// 					[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
		// 					[undefined, "", " ", null].includes(row.TaskListName) ? "-" : row.TaskListName,
		// 					[undefined, "", " ", null].includes(row.TaskStatus) ? "-" : row.TaskStatus,
		// 					[undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
		// 					[undefined, "", " ", null].includes(row.TaskUrl) ? "#" : row.TaskUrl,
		// 					[undefined, "", " ", null].includes(row.TaskStartDate) ? "-" : row.TaskStartDate,
		// 					[undefined, "", " ", null].includes(row.TaskDueDate) ? "-" : row.TaskDueDate,
		// 					[undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CreatorRowid,
		// 					row.TaskDescription
		// 				];
		// 				data.push(result);
		// 			}
		// 			if(data.length === 0)
		// 			{
		// 				reject("none");
		// 			}
		// 			resolve(data);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
	});
}

// export function getFromTeamDetails(queryString = "SELECT * FROM TeamDetails")
// {
// 	return new Promise((resolve, reject) => {
// 		const start = global.performance.now();
// 		var zcql = window.catalyst.ZCatalystQL;
// 		var zcqlPromise  = zcql.executeQuery(queryString);

// 		zcqlPromise
// 				.then((response) => {
// 					// console.log(response);
// 					const end = global.performance.now();
// 					console.log(`took ${end-start} ms`);

// 					if(response.status === 400)
// 					{
// 						reject("error");
// 					}

// 					var data = [];
// 					for(const item of response.content)
// 					{
// 						data.push(item);
// 					}
// 					if(data.length === 0)
// 					{
// 						reject("none");
// 					}
// 					resolve(data);
// 				})
// 				.catch((err) => {
// 					reject(err);
// 				});
// 	});
// }


// export function getData()
// {
// 	console.log(window.catalyst.table.tableId(5781000006858394));
// }

// export function getMyPagedRows(hasNext = true, next_token = undefined)
// {
// 	const start = global.performance.now();
//     if (!hasNext) javascript function that resolves a promise and returns the response value to the caller and how to wait for this function to complete and get the value from the call
//     {
//         return;
//     } 

//     window.catalyst.table
//         .tableId(5781000006856839)
//         .getPagedRows({ next_token, max_rows: 100 }) 
//         .then(resp => {
//             console.log('rows : ', resp.content); //Fetch the rows from the table
//             const end = global.performance.now();
// 	    	console.log(`${end-start} ms`);
//             return getMyPagedRows(resp.more_records, resp.next_token); 
//         })
//         .catch((err) => {
//             console.log(err.toString());
//             hasNext = false;
//         }); 
// }


// export function milestoneTransformFunction(response) {
// 	var data = [];
// 	for (const item of response) {
// 		const row = item.TaskBreaker;
// 		const result = [
// 			row.TaskName,
// 			[undefined, "", " ", null].includes(row.MilestoneType) ? "-" : row.MilestoneType,
// 			[undefined, "", " ", null].includes(row.Component) ? "-" : row.Component,
// 			!(["High", "Low", "Medium"].includes(row.TaskPriority)) ? "None" : row.TaskPriority,
// 			[undefined, "", " ", null].includes(row.MilestoneName) ? "-" : row.MilestoneName,
// 			[undefined, "", " ", null].includes(row.TaskListName) ? "-" : row.TaskListName,
// 			[undefined, "", " ", null].includes(row.TaskStatus) ? "-" : row.TaskStatus,
// 			[undefined, "", " ", null].includes(row.DRI) ? "-" : row.DRI,
// 			[undefined, "", " ", null].includes(row.TaskUrl) ? "#" : row.TaskUrl,
// 			[undefined, "", " ", null].includes(row.TaskStartDate) ? "-" : row.TaskStartDate,
// 			[undefined, "", " ", null].includes(row.TaskDueDate) ? "-" : row.TaskDueDate,
// 			[undefined, "", " ", null].includes(row.CreatorRowid) ? "-" : row.CreatorRowid,
// 			row.TaskDescription
// 		];
// 		data.push(result);
// 	}
// 	if (data.length === 0) {
// 		return undefined;
// 	}
// 	return data;
// }
